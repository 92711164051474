import { JSONAPISerializer } from 'miragejs';

export default class ArticleSeralizer extends JSONAPISerializer {
  keyForAttribute(key /* relationship, method */) {
    return key;
  }

  getHashForIncludedResource(resource) {
    let serializer = this.serializerFor(resource.modelName);
    if (serializer.type === 'issue') {
      let hash = serializer.getHashForResource(resource);
      let hashWithRoot = { included: this.isModel(resource) ? [hash] : hash };
      let addToIncludes = [];

      if (!this.hasQueryParamIncludes()) {
        addToIncludes = this.getAddToIncludesForResource(resource);
      }

      // Something is squirrely with how serialization works, and I think it's because of the odd
      // discrepancy in having an json-api serialized response include a non-json-api serialized payload.

      // Issues are serialized as plain json, but the articles are serialized as json-api.
      hashWithRoot.included = hashWithRoot.included?.[0]?.filter(
        (item) => item?.id
      );

      return [hashWithRoot, addToIncludes];
    } else {
      return super.getHashForIncludedResource(resource);
    }
  }

  links(article) {
    return {
      issues: {
        related: `/v2/libraries/${article.libraryId}/issues/${article.issueId}`,
      },
    };
  }
}
