/***
 * An AuthRequiredError is used by our data adapters
 * to signal that the API is rejecting requests
 * because the client is not sending over any
 * valid authentication credential.
 *
 * @class AuthRequiredError
 */

export default function AuthRequiredError(payload) {
  Error.call(this, `The API reported that an authentication credential is required`);
  this.payload = payload;
}

AuthRequiredError.prototype = Object.create(Error.prototype);
