import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { all, resolve } from 'rsvp';
import window from 'ember-window-mock';
import Route from '@ember/routing/route';
import checkQueryParamsForPmid from 'libkey-web/utils/check-query-params-for-pmid';
import checkQueryParamsForDOI from 'libkey-web/utils/check-query-params-for-doi';
import prepareArticleLoadingBoxModel from 'libkey-web/utils/prepare-article-loading-box-model';
import 'url-search-params-polyfill';

export default class ArticleOpenurlRoute extends Route {
  @service analytics;
  @service applicationSession;
  @service router;
  @service unpaywall;
  @service store;
  @service flashMessages;
  @service intl;
  @service auth;

  async beforeModel(transition) {
    let { to: { params, queryParams } } = transition;

    const analytics = this.analytics;
    if (queryParams.disableRedirect) {
      this.disableRedirect = true;
    } else {
      this.disableRedirect = false;
    }

    const libraryId = params.library_id;
    analytics.setCustomDimension(1, libraryId); // Setting library ID because this route is not a child route of the library route
    analytics.setProductDimension('LibkeyLink');

    const applicationSession = this.applicationSession;
    const libraryToken = applicationSession.lookupLibraryToken(libraryId);

    if (!libraryToken) {
      await this.auth.authenticateLibrary({libraryId}, transition);
    } else {
      applicationSession.set('selectedLibrary', libraryId);
    }

    await this.intl.setLocaleFromSelectedLibrary();
  }

  getRedirectUrl(linkResolverUrlBase) {
    const urlBase = linkResolverUrlBase;
    if (!urlBase) {
      return '';
    }

    const urlBaseQuerystring = urlBase.split('?')[1];
    const urlBaseBeforeQuerystring = urlBase.split('?')[0];

    const originalQuerystring = this.getQuerystring();

    if (originalQuerystring && urlBaseQuerystring) {
      return `${urlBaseBeforeQuerystring}?${originalQuerystring}&${urlBaseQuerystring}`;
    } else if (originalQuerystring) {
      return `${urlBaseBeforeQuerystring}?${originalQuerystring}`;
    } else if (urlBaseQuerystring) {
      return `${urlBaseBeforeQuerystring}?${urlBaseQuerystring}`;
    } else {
      return urlBaseBeforeQuerystring;
    }

  }

  model(params, transition) {

    async function getArticle() {
      let article;
      let avoidUnpaywall;
      if (doi) {
        this.requestedId = doi;
        article = await this.store
          .queryRecord("article", {
            doi: doi,
            reload: true,
            include: "issue,journal",
          })
          .catch((err) => {
            if (err.status === 404) {
              avoidUnpaywall = err.payload?.meta?.avoidUnpaywall;
              return;
            }
            throw err;
          });
      } else if (pmid) {
        // No DOI found? check for a PMID
        this.requestedId = pmid;
        article = await this.store
          .queryRecord("article", {
            pmid,
            reload: true,
            include: "issue,journal",
          })
          .catch((err) => {
            if (err.status === 404) {
              avoidUnpaywall = err.payload?.meta?.avoidUnpaywall;
              return;
            }
            throw err;
          });
      }

      // if NO DOI or PMID, we can't even try to fetch an article
      return {
        article,
        avoidUnpaywall
      };
    }

    const doi = checkQueryParamsForDOI(transition);
    const pmid = checkQueryParamsForPmid(transition);

    let parseResult;
    if (doi) {
      parseResult = 'found_doi';
    } else if (pmid){
      parseResult = 'found_pmid';
    }

    const libraryId = params.library_id;
    const findLibrary = this.store.findRecord('library', libraryId);
    const findArticle = getArticle.bind(this)(doi, pmid);

    return all([findArticle, findLibrary])
      .then(async ([getArticleResult, library]) => {
        const {
          article,
          avoidUnpaywall
        } = getArticleResult;

        if (!library.linkResolverUrlBase) {
          console.log('Library had no linkResolverBaseURL, cannot fail over to library link resolver');
          return window.location.replace('https://thirdiron.com/error-libkey-link-not-properly-configured/');
        }

        const defaultMode = library.libkeyLinkDefaultMode;

        const articleRetracted = article && !!article.retractionDoi;
        const articleHasExpressionOfConcern = article && !!article.expressionOfConcernDoi;
        const articleIsAbandoned = !!article && !!article.abandoned;
        const journalIsProblematic = article && article.journal && !!article.journal.get('problematicJournalCabellsUrl');

        // couldn't retrieve any article, or we got an article, but we can't send them anywhere.
        // if we have a doi on hand, use it to check unpaywall for urls.  If we can't get
        // unpaywall urls, forward to the library's link resolver
        // forward any abandoned articles to the link resolver
        if (!article || (!article.contentLocation && !(article.fullTextFile) ||
          articleIsAbandoned
        )) {

          const requestedDoi = (article ? article.doi : doi) || doi;

          if (defaultMode === 'format_chooser' &&
            !!requestedDoi &&
            (!article || !article.unpaywallDataSuppressed) &&
            (!library || library.useLiveUnpaywallCalls) &&
            !avoidUnpaywall &&
            !articleIsAbandoned) {

            const unpaywallUrls = await this.unpaywall.getUnpaywallUrls(requestedDoi);
            const unpaywallHasLink = unpaywallUrls.articlePDFUrl ||
              unpaywallUrls.articleLinkUrl ||
              unpaywallUrls.manuscriptArticlePDFUrl ||
              unpaywallUrls.manuscriptArticleLinkUrl;
            const shouldUseUnpaywallLink = !article ||
              !(article.avoidUnpaywallPublisherLinks && unpaywallUrls.linkHostType === 'publisher');

            if (unpaywallHasLink && shouldUseUnpaywallLink) {

              return prepareArticleLoadingBoxModel(resolve(article), findLibrary)
               .then((model) => {
                 return {
                   ...model,
                   unpaywallUrls,
                   parseResult,
                   showRememberChoiceCheckbox: false
                 };
               });
            }

          }

          if (!article || (!articleRetracted && !articleHasExpressionOfConcern && !journalIsProblematic) || articleIsAbandoned) {
            window.location.replace(this.getRedirectUrl(library.linkResolverUrlBase));
            return;
          }
        }


        if (defaultMode === 'pdf_express' && !articleRetracted && !articleHasExpressionOfConcern && !journalIsProblematic) {

          if (!article.fullTextFile) {
            window.location.replace(this.getRedirectUrl(library.linkResolverUrlBase));
            return;
          }

          // transition to pdf route
          this.router.transitionTo('article-full-text-file', libraryId, article.id );
          return;
        } else if (defaultMode === 'article_link_express' && !articleRetracted && !articleHasExpressionOfConcern && !journalIsProblematic) {
          if (!article.contentLocation) {
            window.location.replace(this.getRedirectUrl(library.linkResolverUrlBase));
            return;
          }

          this.router.transitionTo('new-article-content-location', libraryId, article.id );
          return;
        } else if (defaultMode === 'go_to_full_text' && !articleRetracted && !articleHasExpressionOfConcern && !journalIsProblematic) {
          if (article.fullTextFile) {
            this.router.transitionTo('article-full-text-file', libraryId, article.id );
            return;
          }

          if (article.contentLocation) {
            this.router.transitionTo('new-article-content-location', libraryId, article.id );
            return;
          }
        }

        const formatPreference = this.applicationSession.formatPreference;

        const now = new Date();
        if (!formatPreference || (Date.parse(formatPreference.expiresAt) < now) || articleRetracted || articleHasExpressionOfConcern || journalIsProblematic) {
          // either no format preference in effect or the article is retracted or EOC,
          // show format chooser if there are options to choose from
          return prepareArticleLoadingBoxModel(resolve(article), findLibrary)
            .then((model) => {
              return {
                ...model,
                showRememberChoiceCheckbox: true
              };
            });
        }

        if (formatPreference.format === 'pdf' && !!article.fullTextFile) {
          this.router.transitionTo('article-full-text-file', libraryId, article.id );
          return;
        }

        if (formatPreference.format === 'web-link' && !!article.contentLocation) {
          this.router.transitionTo('new-article-content-location', libraryId, article.id );
          return;
        }

        // We're showing the format chooser, return whatever model we need
        // but pass the flag that prevents the remember preference checkbox
        // from displaying
        return prepareArticleLoadingBoxModel(resolve(article), findLibrary)
          .then((model) => {
            return {
              ...model,
              showRememberChoiceCheckbox: false
            };
          });

      });
  }

  afterModel(model) {
    if (!model) return;

    if (!model.library?.subscriptions?.libkey?.active) {
      if (this.disableRedirect) {
        return;
      }
      this.flashMessages.alert('This library is not subscribed to LibKey.');
      window.location.replace('https://thirdiron.com/libkey-link-subscription-expired/')
      return;
    }

  }

  setupController(controller, model) {
    if (!model) {
      controller.set('showNothing', true);
      return;
    }

    controller.set('article', model.article);
    controller.set('requestedId', this.requestedId);
    controller.set('issue', model.issue);
    controller.set('library', model.library);
    controller.set('journal', model.journal);
    controller.set('showRememberChoiceCheckbox', model.showRememberChoiceCheckbox);
    controller.set('parseResult', model.parseResult);
    controller.set('unpaywallUrls', model.unpaywallUrls);
    controller.set('accessOptionsUrl', this.getRedirectUrl(model.library.linkResolverUrlBase));
  }

  getQuerystring() {
    const router = this.router;
    if (!router.location || !router.location.path) {
      // Prefer the router's take on the location in case we're dealing with a transition from another
      // ember route in which case the value in the location bar would be out of date to be replaced by
      // the value from the router, but sometimes the router doesn't have any location.path property.  In that
      // case fall back to the what the window says the location bar says.
      return `${window.location.href.split('?')[1]}`;
    }
    const queryString = router.location.path.split('?')[1];
    return queryString;
  }

  @action
  loading/*transition*/() {
    return false;
  }
}


