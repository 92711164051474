import {
  discoverEmberDataModels,
  applyEmberDataSerializers,
} from 'ember-cli-mirage';
import { createServer, Response } from 'miragejs';

export default function (config) {
  let finalConfig = {
    ...config,
    // Remove discoverEmberDataModels if you do not want ember-cli-mirage to auto discover the ember models
    models: {
      ...discoverEmberDataModels(config.store),
      ...config.models,
    },
    // uncomment to opt into ember-cli-mirage to auto discover ember serializers
    serializers: applyEmberDataSerializers(config.serializers),
    routes,
  };

  return createServer(finalConfig);
}

function routes() {
  // These comments are here to help you get started. Feel free to delete them.
  /*
    Config (with defaults).

    Note: these only affect routes defined *after* them!
  */
  // this.urlPrefix = '';    // make this `http://localhost:8080`, for example, if your API is on a different server
  // this.namespace = '';    // make this `/api`, for example, if your API is namespaced
  // this.timing = 400;      // delay for each request, automatically set to 0 during testing
  /*
    Shorthand cheatsheet:

    this.get('/posts');
    this.post('/posts');
    this.get('/posts/:id');
    this.put('/posts/:id'); // or this.patch
    this.del('/posts/:id');

    https://miragejs.com/docs/getting-started/overview/
  */

  this.passthrough('/translations/*.json')

  this.post('/v2/api-tokens', function (/* schema, request */) {
    const tomorrow = new Date(
      new Date().getTime() + 24 * 60 * 60 * 1000
    ).toISOString();

    // if (requestBody.existingToken === 'USER-TOKEN') {
    return new Response(
      200,
      { 'Content-Type': 'application/json' },
      JSON.stringify({
        'api-tokens': [
          {
            id: 'USER-TOKEN',
            type: 'api-tokens',
            permission_grants: [],
            expires_at: tomorrow,
            links: {
              user: {
                type: 'users',
                id: 1313,
                email: 'test-user.thirdiron.com',
                couchdbDatabaseLocation:
                  'https://sample.database-url.com/user-1313',
              },
            },
          },
        ],
      })
    );
    // }

    // return new Response(
    //   404,
    //   { 'Content-Type': 'application/json' },
    //   JSON.stringify({})
    // );
  });

  this.post('/v2/fulfillment-requests', (schema, request) => {
    const requestBody = JSON.parse(request.requestBody);
    const fulfillmentRequest = schema.fulfillmentRequests.create({
      ...requestBody.data.attributes,
      status: 'pending',
      created: new Date().toISOString(),
    });
    return fulfillmentRequest;
  });

  this.patch('/v2/fulfillment-requests/:id', (schema, request) => {
    // We want to update the lastUpdated field whenever the fulfillment request is updated
    const fulfillmentRequestId = request.params.id;
    const requestBody = JSON.parse(request.requestBody);
    const fulfillmentRequest =
      schema.fulfillmentRequests.find(fulfillmentRequestId);
    fulfillmentRequest.update({
      ...requestBody.data.attributes,
      lastUpdated: new Date().toISOString(),
    });
    return fulfillmentRequest;
  });

  this.get('/v2/fulfillment-requests/:id');
  this.get('/v2/libraries', (schema, { queryParams = {} }) => {
    var libraries = schema.libraries.all();
    if (queryParams['filter[library_group_id]']) {
      libraries = libraries.filter(
        (library) =>
          library.libraryGroupId === queryParams['filter[library_group_id]']
      );
    }

    return libraries;
  });

  this.get('/v2/libraries/:id');
  this.get('/v2/libraries/:id/subjects');
  this.get('/v2/libraries/:id/subjects/:subject_id');
  this.get('/v2/libraries/:id/subjects/:subject_id/bookcases');
  this.get('/v2/libraries/:id/subjects/:subject_id/bookshelves');
  this.get('/v2/libraries/:id/subjects/:subject_id/journals');

  this.get('/v2/libraries/:id/bookcases/:bookcase_id');
  this.get('/v2/libraries/:id/bookcases/:bookcase_id/bookshelves');
  this.get('/v2/libraries/:id/bookcases/:bookcase_id/journals');

  this.get('/v2/libraries/:id/bookshelves/:bookshelf_id');
  this.get('/v2/libraries/:id/bookshelves/:bookshelf_id/journals');

  this.get('/v2/libraries/:id/journals/:journal_id');
  this.get('/v2/libraries/:id/journals/:journal_id/subjects');
  this.get('/v2/libraries/:id/journals/:journal_id/bookshelves');
  this.get('/v2/libraries/:id/journals/:journal_id/issues');
  this.get('/v2/libraries/:id/journals/:journal_id/publication-years');
  this.get('/v2/libraries/:id/journals/:journal_id/issues/current');
  this.get('/v2/libraries/:id/journals/:journal_id/issues/latest-full-text');
  this.get('/v2/libraries/:id/journals/:journal_id/issues/:issue_id/articles');
  this.get('/v2/libraries/:id/journals/:journal_id/articles-in-press');
  this.get('/v2/libraries/:id/issues/:issue_id');
  this.get('/v2/libraries/:id/search');

  this.get('/v2/crossref/:doi/:id', (schema, request) => {
    // Ensure we get a token
    if (!request.requestHeaders['Authorization']) {
      return new Response(401);
    }

    return new Response(404);
  });

  this.get('/v2/library-groups', (schema, { queryParams = {} }) => {
    let libraryGroups = schema.libraryGroups.all();

    if (queryParams['filter[holdings_tool_subdomain]']) {
      libraryGroups = libraryGroups.filter(
        (libraryGroup) =>
          libraryGroup.holdings_tool_subdomain ===
          queryParams['filter[holdings_tool_subdomain]']
      );
    }

    return libraryGroups;
  });

  this.get('/v2/journals');

  this.get('/v2/articles');
  this.get('/v2/articles/:id', (schema, request) => {
    const idParam = decodeURIComponent(request.params.id);
    let article;

    if (idParam.substring(0, 4) === 'doi:') {
      const requestedDOI = idParam.substring('doi:'.length);
      article = schema.articles.findBy({ doi: requestedDOI });
    } else if (idParam.substring(0, 5) === 'pmid:') {
      const requestedPMID = idParam.substring('pmid:'.length);
      article = schema.articles.findBy({ pmid: requestedPMID });
    } else {
      article = schema.articles.findBy({ id: idParam });
    }

    if (!article) {
      return new Response(
        404,
        {},
        {
          errors: [
            {
              links: {
                pubmedFallbackURL: 'http://example.com/pubmed-fallback-url',
              },
            },
          ],
        }
      );
    } else if (article.suppressed && article.avoidUnpaywall) {
      return new Response(
        404,
        {},
        {
          errors: [
            {
              links: {
                pubmedFallbackURL: 'http://example.com/pubmed-fallback-url',
              },
            },
          ],
          meta: {
            avoidUnpaywall: true,
          },
        }
      );
    } else if (article.suppressed) {
      return new Response(
        404,
        {},
        {
          errors: [
            {
              links: {
                pubmedFallbackURL: 'http://example.com/pubmed-fallback-url',
              },
            },
          ],
        }
      );
    }

    return article;
  });
}
