export default function localeIsSupported(localeToCheck, library) {
  if (!localeToCheck || !library) {
    return {
      supported: false,
    };
  }

  const languagesSupported = (library.languagesSupported || "").split(",");

  const exactMatch = languagesSupported.some(
    (supportedLocale) =>
      supportedLocale.toLowerCase() === localeToCheck.toLowerCase()
  );

  if (exactMatch) {
    return {
      supported: true,
      supportedLocale: localeToCheck.toLowerCase(),
    };
  }

  // Check for a partial match.  So if browser's locale is fr-ca, consider fr-fr usable and set it as the application's locale
  for (let supportedLocale of languagesSupported) {
    if (
      supportedLocale.split("-")[0].toLowerCase() ===
      localeToCheck.split("-")[0].toLowerCase()
    ) {
      return {
        supported: true,
        supportedLocale,
      };
    }
  }

  // Nothing matched.  The locale we're checking isn't supported at all
  return {
    supported: false,
  };
}
