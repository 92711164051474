import { hash, resolve } from 'rsvp';
import EmberObject from '@ember/object';

let loadBelongsTo = async (source, relationshipName) => {
  if (source[relationshipName]) {
    return resolve(source[relationshipName]);
  } else {
    // TODO: determine if this is still necessary
    return source.fixedBelongsToLoad(relationshipName);
  }
}

let loadJournal = async (article) => {
  return await loadBelongsTo(article, 'journal');
}

let loadIssue = async (article, options = {}) => {
  let { library, journal } = options;
  if (article.inPress) {
    return EmberObject.create({
      library,
      //TODO: Validate why article.library is missing data properties.
      //Seems like article.library is maybe returning the the unauthenticated library?
      //JIRA https://thirdiron.atlassian.net/browse/BZ-3585
      journal,
      title: 'Articles in Press',
      date: new Date().toISOString().split('T')[0],
      year: new Date().getFullYear()
    });
  } else {
    return await loadBelongsTo(article, 'issue');
  }
}

export default async function prepareArticleLoadingBoxModel(findArticleWithJournalAndIssue, findLibrary) {
  let article = await findArticleWithJournalAndIssue
  let library = await findLibrary

  if (!article) {
    return hash({
      library
    });
  } else {
    let journal = await loadJournal(article);
    let issue   = await loadIssue(article, { library, journal });

    // We have to relate the journal and issue models that came back to the selected library
    // or else our adapters won't be able to construct API URLs properly for them
    if (library?.journals && journal) {
      library.journals.push(journal);
    }

    // If we have an actual issue model rather than a articles-in-press "issue"
    // set it's library relationship too
    if (!article.inPress && library?.issues && issue) {
      library.issues.push(issue);
    }

    return hash({
      article: article,
      issue: issue,
      library: library,
      journal: journal || article.journal
    });
  }
}
