import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { later, scheduleOnce, next } from '@ember/runloop';
import { all } from 'rsvp';
import Route from '@ember/routing/route';

export default class IndexRoute extends Route {
  @service errorReporter;
  @service auth;
  @service applicationSession;
  @service analytics;
  @service router;
  @service store;
  @service intl;

  constructor() {
    super(...arguments);
    this.router.on('routeDidChange', this.routeDidChange.bind(this));
  }

  async beforeModel() {
    const analytics = this.analytics;
    if (!analytics.getProductDimension()) {
      analytics.setProductDimension('LibkeyIO');
    }

    await this.intl.setLocaleFromSelectedLibrary();

    const applicationSession = this.applicationSession;
    if (
      applicationSession.transitioningFromWayflessArticleOrPmid ||
      applicationSession.transitioningFromArticleOrPmid
    ) {
      this.rightPanelCoverClass = "visible";
      this.inputContainerClass = "transparent";
      this.technologyRowClass = "transparent";
      applicationSession.set("transitioningFromArticleOrPmid", undefined);
      applicationSession.set(
        "transitioningFromWayflessArticleOrPmid",
        undefined
      );
    } else if (
      applicationSession.transitioningFromChooseLibrary &&
      !applicationSession.selectedLibrary
    ) {
      this.inputContainerClass = "transparent";
      this.technologyRowClass = "transparent";
      this.foregroundPanelClass = "end-of-slide";
      this.backgroundPanelClass = "end-of-slide";
      this.slideContainerClass = "mid-animation";
    } else {
      this.rightPanelCoverClass = undefined;
      this.inputContainerClass = undefined;
      this.slideContainerClass = undefined;
      this.foregroundPanelClass = undefined;
      this.backgroundPanelClass = undefined;
    }

  }

  model() {
    const applicationSession = this.applicationSession;
    const selectedLibraryId = applicationSession.selectedLibrary;
    const holdingsToolLibraryGroup = applicationSession.holdingsToolLibraryGroup;
    let findLibrary,
      getLibraries;

    if (!selectedLibraryId) {
      if (holdingsToolLibraryGroup) {
        applicationSession.set('selectedLibrary', undefined);
        return this.router.transitionTo('choose-library-no-article');
      }
      getLibraries = this.store.findAll('library')
        .then(libs => libs.filter(lib => lib.subscriptions.libkey.active));
    } else if (selectedLibraryId !== 'unaffiliated') {
      if (this.libraryNeedsReload) {
        this.libraryNeedsReload = false;
        findLibrary = this.store.findRecord("library", selectedLibraryId, {
          reload: true,
        });
      } else {
        findLibrary = this.store.findRecord('library', selectedLibraryId);
      }
    }

    return all([findLibrary, getLibraries])
      .then(([library, selectableLibraries]) => {
        return {
          library,
          selectableLibraries,
          holdingsToolLibraryGroup
        };
      });
  }

  setupController(controller, model) {
    const applicationSession = this.applicationSession;
    const selectedLibraryId = applicationSession.selectedLibrary;

    controller.set('library', model.library);
    controller.set('holdingsToolLibraryGroup', model.holdingsToolLibraryGroup);
    controller.set('unaffiliated', selectedLibraryId === 'unaffiliated');
    controller.set('selectableLibraries', model.selectableLibraries);
    controller.set('rightPanelCoverClass', this.rightPanelCoverClass);
    controller.set('inputContainerClass', this.inputContainerClass);
    controller.set('foregroundPanelClass', this.foregroundPanelClass);
    controller.set('backgroundPanelClass', this.backgroundPanelClass);
    controller.set('slideContainerClass', this.slideContainerClass);
  }

  routeDidChange() {
    const applicationSession = this.applicationSession;
    //eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
    scheduleOnce('afterRender', () => {
      // schedule on next after afterRender event because on safari
      // the DOM aparently still isn't ready with the new markup
      // on 'afterRender'
      next(() => {
        if (this.isDestroyed || this.isDestroying) {
          return
        }

        //eslint-disable-next-line ember/no-controller-access-in-routes
        const controller = this.controllerFor('index');
        controller.set('searchValue', '');
        if (applicationSession.transitioningFromChooseLibrary &&
            !applicationSession.selectedLibrary) {

          applicationSession.set('transitioningFromChooseLibrary', undefined);
          controller.set('foregroundPanelClass', 'backward-mid-animation');
          controller.set('backgroundPanelClass', 'backward-mid-animation');
          later(() => {
            controller.set('slideContainerClass', 'backward-end-of-slide');
            controller.set('foregroundPanelClass', 'backward-end-of-slide');
            later(() => {
              controller.set('inputContainerClass', 'fade-in');
              controller.set('technologyRowClass', 'fade-in');
            }, 350);
          }, 600);
        } else if (applicationSession.transitioningFromChooseLibraryNoArticle) {
          applicationSession.set('transitioningFromChooseLibraryNoArticle', undefined);
          controller.set('leftPanelContentClass', 'fade-in');
          controller.set('inputContainerClass', 'fade-in');
          controller.set('technologyRowClass', 'fade-in');
        } else {
          controller.set('rightPanelCoverClass', 'fade-out');
          controller.set('inputContainerClass', 'fade-in');
          controller.set('technologyRowClass', 'fade-in');
        }
      });
    });
  }

  @action
  confirmLibrary(selectedLibrary) {
    if (selectedLibrary) {
      const libraryId = selectedLibrary.id;
      this.applicationSession.set('selectedLibrary', libraryId);
      return this.refresh();
    }
  }

  @action
  changeLibrary() {
    this.applicationSession.set('selectedLibrary', undefined);
    return this.router.transitionTo('choose-library', undefined);
  }
}
