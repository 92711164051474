import { inject as service } from '@ember/service';
import { action } from "@ember/object";
import Controller from '@ember/controller';
import { later } from '@ember/runloop';
import { Promise } from 'rsvp';
import { tracked } from '@glimmer/tracking';

export default class WayflessArticleOrPmidController extends Controller {
  @service applicationSession;
  @service media;

  @tracked leftPanelContentClass;
  @tracked rightPanelCoverClass;

  // When transitioning to the choose library page we need to fade both panels
  async performDoubleFade() {
    this.applicationSession.transitioningFromWayflessArticleOrPmid = true;
    this.leftPanelContentClass = "fade-out"
    this.rightPanelCoverClass = "fade-in"
    await new Promise((resolve) => {
      later(this, () => {
        return resolve();
      }, 500);
    });
  }

  @action
  fadeOut() {
    const applicationSession = this.applicationSession;

    this.rightPanelCoverClass = "fade-in";

    return new Promise((resolve) => {
      later(() => {
        applicationSession.set('transitioningFromWayflessArticleOrPmid', true)
        resolve();
      }, 500);
    });
  }

  @action
  fadeOutChangeLibrary() {
    this.applicationSession.selectedLibrary = undefined;
    return this.performDoubleFade();
  }

  @action
  hideResponsiveLanguages() {
    document.querySelector('.language-list-component').style.transform = 'translate(0,0)';
    document.querySelector('.language-list-component').classList.add('hidden');
    document.querySelector('.page-container').style.transform = 'translate(0,0)';
  }
}

