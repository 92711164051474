import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { Promise } from 'rsvp';
import Controller from '@ember/controller';
import { later } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';

export default class ChooseLibraryNoArticleController extends Controller {
  @service applicationSession;
  @service store;
  @service router;
  @service intl;

  @tracked leftPanelContentClass;

  // When transitioning back to the index page,
  // just fade the left panel
  async performLeftFade() {
    this.leftPanelContentClass = "fade-out"
    await new Promise((resolve) => {
      later(this, () => {
        return resolve();
      }, 350);
    });
  }

  @action
  async confirmLibrary(selectedLibrary) {
    const applicationSession = this.applicationSession;
    if (selectedLibrary) {
      await this.performLeftFade();
      const libraryId = selectedLibrary.id
      applicationSession.transitioningFromChooseLibraryNoArticle = true;
      applicationSession.selectedLibrary = libraryId;
      // Clear the store of the library
      this.store.unloadAll('library');
      // Clear the store of all other models that can have library-specific data (like whether the library has access)
      this.store.unloadAll('article');
      this.store.unloadAll('issue');
      this.store.unloadAll('journal');
      await this.intl.setLocaleFromSelectedLibrary();
      return this.router.transitionTo('index');
    }
  }

  @action
  async fadeOutChooseUnaffiliatedVisitIndex() {
    const applicationSession = this.applicationSession;
    await this.performLeftFade();
    applicationSession.transitioningFromChooseLibraryNoArticle = true;
    applicationSession.selectedLibrary = "unaffiliated";
    return this.router.transitionTo('index');
  }
}
