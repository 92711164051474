import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import window from 'ember-window-mock';

export default class ForwardingLinkingRouteRoute extends Route {
  @service analytics;
  @service applicationSession;
  @service unpaywall;
  @service router;
  @service store;
  @service auth;

  async beforeModel(transition) {
    let { to: { params, queryParams } } = transition;
    const analytics = this.analytics;
    const applicationSession = this.applicationSession;

    if (queryParams.disableRedirect) {
      this.disableRedirect = true;
    } else {
      this.disableRedirect = false;
    }

    const libraryId = applicationSession.selectedLibrary;

    const dimensionValue = libraryId || 'no_affiliation_value_set';
    analytics.setCustomDimension(1, dimensionValue);
    analytics.setProductDimension('LibkeyIO');

    const requestedId = params.id_value;

    if (libraryId && libraryId !== 'unaffiliated') {
      await this.analytics.recordEvent({
        category: 'ForwardingLinkSuccess',
        action: requestedId
      });

      return this.router.transitionTo('wayfless-article-or-pmid', libraryId, requestedId);
    } else {
      await this.analytics.recordEvent({
        category: 'ForwardingLinkToIdentifier',
        action: requestedId
      });

      // If its all digits we've got a pmid
      if (requestedId.match(/^\d+$/)) {
        return window.location.replace(`https://pubmed.ncbi.nlm.nih.gov/${requestedId}`);
      }
      // Otherwise treat the id as a DOI and go to doi.org, even if it doesn't look like a DOI
      // We can let doi.org show their error page when the id isn't a DOI
      window.location.replace(`https://doi.org/${requestedId}`);
    }

  }
}
