import checkQueryParamsForId from './check-query-params-for-id';

export default function checkQueryParamsForPmid(transition) {
  let { to: { queryParams }, intent } = transition;

  function checkValueForPmid(paramValue) {
    const pmidRegex = /^((info:pmid\/)|(pmid:))(\d+)$/
    const matchAttempt = pmidRegex.exec(paramValue);
    if (matchAttempt) {
      return matchAttempt[4];
    }
  }

  // First look for an explicit pmid param
  let pmidValue;
  if (intent.url) {
    const [, queryString] = intent.url.split('?');
    const searchParams = new URLSearchParams(queryString);

    pmidValue = searchParams.get("pmid");
  } else {
    pmidValue = queryParams?.pmid;
  }

  if (pmidValue) {
    return pmidValue;
  }

  // Otherwise search all the query params for something that says its a pmid
  return checkQueryParamsForId(transition, checkValueForPmid);
}
