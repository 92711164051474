  // This is the complete list of locales libkey supports. When adding a new locale:

  // 1. Add it to the list of locales here (which is the order it displays in the language list)
  // 2. Add a translations file that matches the locale code in /translations
  // 3. Add the corresponding polyfill case statement to the utils/intl-polyfill.js file

const libkeyLocales = [
  { name: 'English', code: 'en-us', id: 'english'},
  { name: 'Français', code: 'fr-fr', id: 'french', },
  { name: '日本語', code: 'ja', id: 'japanese', },
  { name: 'Deutsch', code: 'de-de', id: 'german', },
  { name: 'Cymraeg', code: 'cy-gb', id: 'welsh', }
]

export default libkeyLocales;
