import { doiRegex } from './doi-utils';
import checkQueryParamsForId from './check-query-params-for-id'

export default function checkQueryParamsForDOI(transition) {
  function checkValueForDOI(paramValue) {
    const matchAttempt = doiRegex.exec(paramValue);
    if (matchAttempt) {
      return matchAttempt[0];
    }
  }

  return checkQueryParamsForId(transition, checkValueForDOI);
}
