import responseHasErrorsInTopLevelArray from "./response-has-errors-in-top-level-array";
import isJsonApi10ErrorResponse from "./is-json-api10-error-response";
import errorIsAuthErrorWithProxyAvailable from "./error-is-auth-error-with-proxy-available";
import _ from "lodash";

export default function getPreproxyFromErrorResponse(errorResponse) {
  // TODO: BZ-4103 Handle both JSON API 1.0
  // and non-standard formats until made constistent
  if (responseHasErrorsInTopLevelArray(errorResponse)) {
    const errorWithPreproxy = _.find(errorResponse.responseJSON, errorIsAuthErrorWithProxyAvailable);
    return errorWithPreproxy.preproxy;
  }

  if (isJsonApi10ErrorResponse(errorResponse)) {
    const errorWithPreproxy = _.find(errorResponse.responseJSON.errors, errorIsAuthErrorWithProxyAvailable);
    return errorWithPreproxy.preproxy;
  }

  return errorResponse.responseJSON.preproxy;
}
