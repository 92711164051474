import responseHasErrorsInTopLevelArray from "./response-has-errors-in-top-level-array";
import isJsonApi10ErrorResponse from "./is-json-api10-error-response";
import errorIsAuthErrorWithSSOGateway from "./error-is-auth-error-with-ssogateway";

export default function responseHasAuthErrorWithSSOGateway(errorResponse) {
 // TODO: BZ-4103 Handle both JSON API 1.0
  // and non-standard format until made consistent

  if (responseHasErrorsInTopLevelArray(errorResponse)) {
    const errors = errorResponse.responseJSON;
    return errors.some((error) => errorIsAuthErrorWithSSOGateway(error));
  }

  if (isJsonApi10ErrorResponse(errorResponse)) {
    const errors = errorResponse.responseJSON.errors;
    return errors.some((error) => errorIsAuthErrorWithSSOGateway(error));
  }

  return errorResponse.responseJSON &&
    errorIsAuthErrorWithSSOGateway(errorResponse.responseJSON);
}
