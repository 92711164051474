import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { all, resolve } from 'rsvp';
import window from 'ember-window-mock';
import Route from '@ember/routing/route';
import 'url-search-params-polyfill';
import { doiRegex } from '../utils/doi-utils';
import prepareArticleLoadingBoxModel from 'libkey-web/utils/prepare-article-loading-box-model';
const pmidRegex = /^\d+$/;

export default class ArticleLklIdentifierPdfexpressRoute extends Route {
  @service analytics;
  @service applicationSession;
  @service router;
  @service unpaywall;
  @service store;
  @service flashMessages;
  @service intl;
  @service auth;

  async beforeModel(transition) {
    let { to: { params, queryParams } } = transition;

    const analytics = this.analytics;
    if (queryParams.disableRedirect) {
      this.disableRedirect = true;
    } else {
      this.disableRedirect = false;
    }

    const libraryId = params.library_id;
    analytics.setCustomDimension(1, libraryId); // Setting library ID because this route is not a child route of the library route
    analytics.setProductDimension('LibkeyIO');

    const applicationSession = this.applicationSession;
    const libraryToken = applicationSession.lookupLibraryToken(libraryId);

    if (!libraryToken) {
      await this.auth.authenticateLibrary({libraryId}, transition);
    } else {
      applicationSession.set('selectedLibrary', libraryId);
    }

    await this.intl.setLocaleFromSelectedLibrary();

  }

  model(params) {

    function getArticle(doi, pmid) {
      if (doi) {
        this.requestedId = doi;
        const findArticleByDOI = this.store
          .queryRecord("article", {
            doi: doi,
            reload: true,
            include: "issue,journal",
          })
          .catch((err) => {
            if (err.status === 404) {
              return;
            }
            throw err;
          });

        return findArticleByDOI;
      }

      // No DOI found? check for a PMID

      if (pmid) {
        this.requestedId = pmid;
        const findArticleByPmid = this.store
          .queryRecord("article", {
            pmid,
            reload: true,
            include: "issue,journal",
          })
          .catch((err) => {
            if (err.status === 404) {
              return;
            }
            throw err;
          });

        return findArticleByPmid;
      }

      // NO DOI or PMID, so we can't even try to fetch an article
      return;
    }

    const id = decodeURIComponent(params.id_value);
    this.requestedId = id;
    const doiMatchAttempt = doiRegex.exec(id);
    const pmidMatchAttempt = pmidRegex.exec(id);
    let doi;
    let pmid;
    let parseResult;
    let findArticle;

    if (doiMatchAttempt) {
      doi = doiMatchAttempt[0];
      parseResult = 'found_doi';
      findArticle = getArticle.bind(this)(doi, pmid);
    } else if (pmidMatchAttempt) {
      pmid = pmidMatchAttempt[0];
      parseResult = 'found_pmid';
      findArticle = getArticle.bind(this)(doi, pmid);
    } else {
      findArticle = resolve();
      parseResult = 'unknown_id_type';
    }


    const libraryId = params.library_id;
    const findLibrary = this.store.findRecord('library', libraryId);

    return all([findArticle, findLibrary])
      .then(async ([article, library]) => {

        if (!library.libkeyFallbackUrl) {
          console.log('Library had no libkeyFallbackUrl, insufficient configuration to handle all edge cases');
          return window.location.replace('https://thirdiron.com/error-libkey-link-not-properly-configured/');
        }

        const requestedDoi = (article ? article.doi : doi) || doi;
        const articleRetracted = article && !!article.retractionDoi;
        const articleHasExpressionOfConcern = article && !!article.expressionOfConcernDoi;
        const journalIsProblematic = article && article.journal && !!article.journal.get('problematicJournalCabellsUrl');

        if (!article || (!article.contentLocation && !(article.fullTextFile))) {

          if ((articleRetracted || articleHasExpressionOfConcern || journalIsProblematic) &&
            requestedDoi &&
            (!article || !article.unpaywallDataSuppressed) &&
            (!library || library.useLiveUnpaywallCalls)) {

            const unpaywallUrls = await this.unpaywall.getUnpaywallUrls(requestedDoi);

            if (unpaywallUrls.articlePDFUrl ||
                unpaywallUrls.articleLinkUrl ||
                unpaywallUrls.manuscriptArticlePDFUrl ||
                unpaywallUrls.manuscriptArticleLinkUrl) {

              return prepareArticleLoadingBoxModel(findArticle, findLibrary)
               .then((model) => {
                 return {
                   ...model,
                   unpaywallUrls,
                   parseResult,
                   showRememberChoiceCheckbox: false
                 };
               });
            }

          }

          if (doi && library.libkeyFallbackTemplate && !(articleRetracted||articleHasExpressionOfConcern||journalIsProblematic)) {
            const doiFallbackUrl = library.libkeyFallbackTemplate
              .replace('{suspected_doi}', doi)
              .replace('{suspected_doi_encoded}', encodeURIComponent(doi));

            window.location.replace(doiFallbackUrl);
            return;
          } else if (pmid && library.pubmedFallbackTemplate && !(articleRetracted||articleHasExpressionOfConcern||journalIsProblematic)) {
            const pmidFallbackUrl = library.pubmedFallbackTemplate + pmid;
            window.location.replace(pmidFallbackUrl);
            return;
          } else {
            return prepareArticleLoadingBoxModel(findArticle, findLibrary)
             .then((model) => {
               return {
                 ...model,
                 parseResult,
                 showRememberChoiceCheckbox: false
               };
             });
          }
        }


        if (!(articleRetracted||articleHasExpressionOfConcern||journalIsProblematic)) {

          if (!article.fullTextFile) {
            if (doi && library.libkeyFallbackTemplate) {
              const doiFallbackUrl = library.libkeyFallbackTemplate
                .replace('{suspected_doi}', doi)
                .replace('{suspected_doi_encoded}', encodeURIComponent(doi));

              window.location.replace(doiFallbackUrl);
              return;
            } else if (pmid && library.pubmedFallbackTemplate) {
              const pmidFallbackUrl = library.pubmedFallbackTemplate + pmid;
              window.location.replace(pmidFallbackUrl);
              return;
            } else {
              return prepareArticleLoadingBoxModel(findArticle, findLibrary)
               .then((model) => {
                 return {
                   ...model,
                   parseResult,
                   showRememberChoiceCheckbox: false
                 };
               });
            }
          }

          // transition to pdf route
          this.router.transitionTo('article-full-text-file', libraryId, article.id );
          return;
        }

        return prepareArticleLoadingBoxModel(findArticle, findLibrary)
          .then((model) => {
            return {
              ...model,
              showRememberChoiceCheckbox: true
            };
          });

      });
  }

  afterModel(model) {
    if (!model) return;

    if (!model.library?.subscriptions?.libkey?.active) {
      if (this.disableRedirect) {
        return;
      }
      this.flashMessages.alert('This library is not subscribed to LibKey.');
      window.location.replace('https://thirdiron.com/libkey-link-subscription-expired/')
      return;
    }

  }

  setupController(controller, model) {
    if (!model) {
      controller.set('showNothing', true);
      return;
    }

    controller.set('article', model.article);
    controller.set('requestedId', this.requestedId);
    controller.set('issue', model.issue);
    controller.set('library', model.library);
    controller.set('journal', model.journal);
    controller.set('showRememberChoiceCheckbox', model.showRememberChoiceCheckbox);
    controller.set('parseResult', model.parseResult);
    controller.set('accessOptionsUrl', `${model.library.linkResolverUrlBase}${window.location.search}`);
    controller.set('fallbackUrl', model.library.libkeyFallbackUrl);
  }

  getQuerystring() {
    const router = this.router;
    if (!router.location || !router.location.path) {
      // Prefer the router's take on the location in case we're dealing with a transition from another
      // ember route in which case the value in the location bar would be out of date to be replaced by
      // the value from the router, but sometimes the router doesn't have any location.path property.  In that
      // case fall back to the what the window says the location bar says.
      return `${window.location.href.split('?')[1]}`;
    }
    const queryString = router.location.path.split('?')[1];
    return queryString;
  }

  @action
  loading/*transition*/() {
    return false;
  }
}


