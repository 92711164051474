import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class LocaleChooserComponent extends Component {
  @service intl;
  @service applicationSession;
  @service media;

  @tracked showLanguages = false;
  @tracked responsiveIsHidden = true;

  get selectedLanguageName() {
    const primaryLocale = this.intl.primaryLocale;

    if (primaryLocale.startsWith('en')) {
      return 'EN';
    } else if (primaryLocale.startsWith('fr')) {
      return 'FR';
    } else if (primaryLocale.startsWith('ja')) {
      // Needs translation - this should be in Japanese I think
      return 'JA';
    } else if (primaryLocale.startsWith('de')) {
      return 'DE';
    } else if (primaryLocale.startsWith('cy')) {
      return 'CY';
    }


    return undefined;
  }

  get showChooser() {
    const library = this.args.library;
    if (!library) {
      return false;
    }
    const languagesSupported = (library.languagesSupported || '').split(',');

    return languagesSupported.length > 1;
  }

  @action
  hideLanguages() {
    if (!this.isDestroyed) {
      this.showLanguages = false
    }
  }

  @action
  toggle() {
    if (this.media.isMobile || this.media.isTabletportrait) {
      if (this.responsiveIsHidden) {
        // Set class on element so test can easily see we've styled it to be hidden
        // off the right side of the page
        document.querySelector('.page-container').style.transform = 'translate(-45%, 0)';
        document.querySelector('.language-list-component').classList.remove('test-hidden');
        document.querySelector('.language-list-component').style.display = 'block';
      } else {
        document.querySelector('.page-container').style.transform = 'translate(0, 0)';
        document.querySelector('.language-list-component').classList.add('test-hidden');
        document.querySelector('.language-list-component').style.display = 'none';
      }
      if (!this.isDestroyed) {
        this.responsiveIsHidden = !this.responsiveIsHidden
      }
    } else {
      if (!this.isDestroyed) {
        this.showLanguages = !this.showLanguages
      }
    }
  }
}
