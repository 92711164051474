import { helper } from '@ember/component/helper';

export default helper(function libraryWayfEntriesFromLibraries(params/*, hash*/) {
  let libraries = params[0];

  if (!libraries || !libraries.length) {
    return libraries;
  } else if (libraries.slice()) {
    libraries = libraries.slice();
  }

  const listEntries = libraries.flatMap((l) => {
    if (!l.aliases || !l.aliases.length) {
      // If no aliases return just an entry for the library
      return {
        entryName: l.name,
        model: l
      };
    } else {
      // if aliases return an entry for each alias and one for the library
      const aliasEntries = l.aliases.map((a) => {
        return {
          entryName: a,
          model: l
        };
      });

      aliasEntries.push({
        entryName: l.name,
        model: l
      });

      return aliasEntries;
    }
  });

  const sortedFilteredEntries = listEntries
    .filter((entry) => {
      return !!entry.entryName;
    })
    .sort((a, b) => {
      return a.entryName.localeCompare(b.entryName);
    });

  return sortedFilteredEntries;
});
