/* eslint-disable ember/no-mixins */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { scheduleOnce, later } from '@ember/runloop';
import window from 'ember-window-mock';
import _ from 'lodash';
import prepareArticleLoadingBoxModel from 'libkey-web/utils/prepare-article-loading-box-model';
import { macroCondition, isTesting } from '@embroider/macros';

export default class ArticleDocumentDeliveryFulfillmentRoute extends Route {
  @service analytics;
  @service applicationSession;
  @service router;
  @service store;
  @service intl;
  @service auth;

  async beforeModel(transition) {
    let { to: { params, queryParams } } = transition;

    const analytics = this.analytics;
    const libraryId = params.library_id;
    const articleId = params.article_id;

    analytics.setCustomDimension(1, libraryId); // Setting library ID because this route is not a child route of the library route

    const applicationSession = this.applicationSession;
    const libraryToken = applicationSession.lookupLibraryToken(libraryId);
    // If we got tracking info, record an analytics event then remove the tracking param so we don't double report
    if (queryParams.utm_source && queryParams.utm_source.toLowerCase().includes('api_')) {
      await analytics.recordEvent({
        category: 'PublicAPIDocumentDeliveryFulfillment',
        action: articleId
      });

      analytics.setProductDimension('LibkeyAPI');

      this.router.replaceWith('article-document-delivery-fulfillment', libraryId, articleId, { queryParams: _.omit(queryParams, 'utm_source') });
    } else if (queryParams.utm_source && queryParams.utm_source.toLowerCase() === 'nomad') {
      analytics.setProductDimension('LibkeyNomad');
    } else {
      if (!analytics.getProductDimension()) {
        analytics.setProductDimension('LibkeyIO');
      }
    }

    if (!libraryToken && libraryId !== 'unaffiliated') {
      await this.auth.authenticateLibrary({libraryId}, transition);
      await this.intl.setLocaleFromSelectedLibrary();
      return;
    } else {
      applicationSession.set('selectedLibrary', libraryId);
      await this.intl.setLocaleFromSelectedLibrary();
      return;
    }

  }

  model(params) {
    const findArticleWithJournalAndIssue = this.store.findRecord('article', params.article_id, { reload: true, include: 'issue,journal' });
    const libraryId = params.library_id;

    let findLibrary;
    if (libraryId !== 'unaffiliated') {
      findLibrary = this.store.findRecord('library', libraryId);
    }

    return prepareArticleLoadingBoxModel(findArticleWithJournalAndIssue, findLibrary)
      .catch((error) => {
        if (error.status === 404) {
          let error = new Error("Article not found");
          error.articleNotFound = true;
          throw error;
        } else {
          throw error;
        }
      });
  }

  async afterModel(model, transition) {
    let { to: { params, queryParams } } = transition;
    const journalId = model.journal.id;
    const articleId = params.article_id;

    var timeUntilTransition = 100;

    if (macroCondition(isTesting())) {
      timeUntilTransition = 0;
    }

    if (!model.article.documentDeliveryFulfillmentUrl) {
      return;
    }

    this.hasDocumentDeliveryFulfillmentUrl = true;

    if (queryParams.disableRedirect) {
      return;
    }

    await this.analytics.recordEvent({
      category: 'DocumentDeliveryFulfillment',
      action: articleId,
      value: journalId,
      documentDeliveryProvider: model.article.documentDeliveryProvider,
    });

    //eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
    scheduleOnce('afterRender', this, () => {
      later(this, () => {
        window.location.replace(model.article.documentDeliveryFulfillmentUrl);
      }, timeUntilTransition);
    });
  }

  setupController(controller, model) {
    controller.set('article', model.article);
    controller.set('issue', model.issue);
    controller.set('library', model.library);
    controller.set('journal', model.journal);
    controller.set('hasDocumentDeliveryFulfillmentUrl', this.hasDocumentDeliveryFulfillmentUrl);
    controller.set('loadingType', 'documentDeliveryFulfillment');
  }

  @action
  loading/*transition*/() {
    // Don't let the loading bubble up to the application
    // route to prevent it from setting the background color
    return false;
  }
}
