import errorIsAuthErrorWithVPNAvailable from "./error-is-auth-error-with-vpnavailable";
import responseHasErrorsInTopLevelArray from "./response-has-errors-in-top-level-array";
import isJsonApi10ErrorResponse from "./is-json-api10-error-response";
// This is kind of obtuse, but the API token endpoint
// indicates there's a VPN by incuding an ipRangeError
// property on the response

export default function responseHasAuthErrorWithVPNAvailable(errorResponse) {
   // TODO: BZ-4103 Handle both JSON API 1.0
    // and non-standard format until made consistent

    if (responseHasErrorsInTopLevelArray(errorResponse)) {
      const errors = errorResponse.responseJSON;
      return errors.some((error) => errorIsAuthErrorWithVPNAvailable(error));
    }

    if (isJsonApi10ErrorResponse(errorResponse)) {
      const errors = errorResponse.responseJSON.errors;
      return errors.some((error) => errorIsAuthErrorWithVPNAvailable(error));
    }

    return errorResponse.responseJSON &&
      errorIsAuthErrorWithVPNAvailable(errorResponse.responseJSON);
}
