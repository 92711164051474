import { inject as service } from '@ember/service';
import { resolve } from 'rsvp';
import Route from '@ember/routing/route';
import { next } from '@ember/runloop';
import { findDoiInInput } from "../utils/doi-utils";
import { findPmidInInput } from "../utils/pmid-utils";
import { scheduleOnce } from "@ember/runloop";
import window from 'ember-window-mock';
import prepareArticleLoadingBoxModel from 'libkey-web/utils/prepare-article-loading-box-model';

export default class ChooseLibraryRoute extends Route {
  @service unpaywall;
  @service applicationSession;
  @service router;
  @service store;
  @service media;
  @service intl;
  @service auth;
  @service analytics;

  async beforeModel() {
    const analytics = this.analytics;
    if (!analytics.getProductDimension()) {
      analytics.setProductDimension('LibkeyIO');
    }
    await this.handleLocaleOnLibraryChooserRoute();
  }

  async model(params) {

    let model;
    let doi;
    let unpaywallData;
    const id = decodeURIComponent(params.id_value);

    let findArticleWithJournalAndIssue,
      parseResult,
      doiLink,
      pubmedLink,
      article;

    const doiResult = findDoiInInput(id);
    const pmidResult = findPmidInInput(id);

    if (doiResult.foundDoi && doiResult.doi !== id) {
      return this.router.transitionTo('choose-library', doiResult.doi);
    } else if (doiResult.foundDoi) {
      doi = doiResult.doi;
      try {
        article = await this.store.queryRecord('article', { doi: doi, reload: true, include: 'issue,journal' })
      } catch(err) {
        if (err.status !== 404) {
          throw err;
        }
      }
      if (!article && doi.endsWith('.')) {
        const doiWithoutPeriod = doi.slice(0, doi.length - 1);
        let articleWithoutPeriod = await this.store.queryRecord('article', { doi: doiWithoutPeriod, reload: true, include: 'issue,journal' });
        if (articleWithoutPeriod) {
          return this.router.transitionTo('choose-library', doiWithoutPeriod);
        }
      }
      parseResult = 'found_doi';
      doiLink = `https://doi.org/${doi}`;
      findArticleWithJournalAndIssue = resolve(article);
    } else if (pmidResult.foundPmid && pmidResult.pmid !== id) {
      return this.router.transitionTo('choose-library', pmidResult.pmid);
    } else if (pmidResult.foundPmid) {
      const pmid = pmidResult.pmid;
      try {
        article = await this.store.queryRecord('article', { pmid, reload: true, include: 'issue,journal' })
      } catch(err) {
        if (err.status !== 404) {
          throw err;
        }
      }
      parseResult = 'found_pmid';
      pubmedLink = `https://www.ncbi.nlm.nih.gov/pubmed/${pmid}`;
      findArticleWithJournalAndIssue = resolve(article);
    } else {
      findArticleWithJournalAndIssue = resolve();
      parseResult = 'unknown_id_type';
    }

    // save the requested id so we can use it in the transition when a library is confirmed
    // or unafilliated is selected
    this.requestedId = id;

    const applicationSession = this.applicationSession;
    const holdingsToolLibraryGroup = applicationSession.holdingsToolLibraryGroup;
    let libraries;
    if (holdingsToolLibraryGroup) {
      libraries = await this.store.query('library', { 'filter[library_group_id]': holdingsToolLibraryGroup.id });
    } else {
      libraries = await this.store.findAll('library');
      libraries = libraries.filter((library) => {
        const subscriptions = library.subscriptions;
        return subscriptions.libkey && subscriptions.libkey.active;
      });
    }

    if (article) {
      model = await prepareArticleLoadingBoxModel(findArticleWithJournalAndIssue, undefined);
    } else if (doi) {
      unpaywallData = await this.unpaywall.getUnpaywallUrls(doi);
    }

    libraries = libraries.filter((library) => {
      const subscriptions = library.subscriptions;

      return subscriptions.libkey && subscriptions.libkey.active;
    });

    return {
      ...model,
      unpaywallData,
      selectableLibraries: libraries,
      parseResult,
      doiLink,
      pubmedLink,
      holdingsToolLibraryGroup
    };
  }

  setupController(controller, model) {
    const applicationSession = this.applicationSession;

    if (applicationSession.transitioningFromLandingPage ||
        applicationSession.transitioningFromWayflessArticleOrPmid) {
      controller.set('leftPanelContentClass', 'invisible');
    }

    controller.set('rightPanelCoverClass', this.rightPanelCoverClass);
    controller.set('requestedId', this.requestedId);
    controller.set('article', model.article);
    controller.set('issue', model.issue);
    controller.set('selectableLibraries', model.selectableLibraries);
    controller.set('holdingsToolLibraryGroup', model.holdingsToolLibraryGroup);
    controller.set('journal', model.journal);

    if (!model.article && model.unpaywallData && model.unpaywallData.title) {
      controller.set('unpaywallArticle', model.unpaywallData);
    }

    next(() => {
      if (this.media.isMobile || this.media.isTabletportrait) {
        window.scrollTo(0, 0);
      }
    });

    // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
    scheduleOnce("afterRender", this, () => {
      if (!controller.isDestroyed) {
        controller.set("leftPanelContentClass", "fade-in");
      }
    });
  }

  async handleLocaleOnLibraryChooserRoute() {
    // the library chooser routes are a little different in that we localize them despite the user
    // being unaffiliated so that we don't get awkward flipping between english and a local language
    const localeOverride = this.applicationSession.localeOverride;

    if (localeOverride) {
      await this.intl.loadAndSetLocale(localeOverride);
      return;
    }
    await this.intl.setLocaleFromBrowserLocale();
  }
}
