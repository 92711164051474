export default function checkQueryParamsForId(transition, valueCheckingFunction) {
  let { to: { queryParams }, intent } = transition;
  if (intent.url) {
    const [, queryString] = intent.url.split('?');
    const searchParams = new URLSearchParams(queryString);

    for (let paramValue of searchParams.values()) {
      let foundId = valueCheckingFunction(paramValue);
      if (foundId) {
        return foundId;
      }
    }
  } else {
    const emberQueryParams = queryParams;
    for (let paramName of Object.keys(emberQueryParams)) {
      const paramValue = emberQueryParams[paramName];

      let foundId = valueCheckingFunction(paramValue);
      if (foundId) {
        return foundId;
      }
    }
  }
}
