import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ApiSessionExpiredError from '../errors/ApiSessionExpiredError';
import AuthRequiredError from '../errors/AuthRequiredError';

export default class ProblematicDomainPageRoute extends Route {
  @service analytics;
  @service applicationSession;
  @service store;
  @service intl;
  @service auth;

  async beforeModel(transition) {
    let { to: { params } } = transition;

    const libraryId = params.library_id;
    this.applicationSession.set('selectedLibrary', libraryId);

    let libraryToken;

    await this.intl.setLocaleFromSelectedLibrary();

    if (libraryId && libraryId !== 'unaffiliated') {
      this.analytics.setCustomDimension(1, libraryId); // Setting library ID because this route is not a child route of the library route
      libraryToken = this.applicationSession.lookupLibraryToken(libraryId);
      if (!libraryToken) {
        return this.auth.authenticateLibrary({libraryId}, transition);
      }
    }
  }

  async model(params) {
    const libraryId = params.library_id;

    let reloadLibrary = false;
    if (libraryId !== 'unaffiliated' && this.libraryNeedsReload) {
      this.libraryNeedsReload = false;
      reloadLibrary = true;
    }

    const findLibrary = libraryId !== 'unaffiliated' ? this.store.queryRecord(
      'library',
      {
        id: libraryId,
        reload: reloadLibrary,
      }
    ): undefined;

    const domainId = params.domain_id;
    const domain = await this.store.findRecord('problematic-domain', domainId);
    const library = await findLibrary;

    return {
      domain,
      library
    };
  }

  setupController(controller, model) {
    controller.set('domain', model.domain);
    controller.set('library', model.library);
  }

  @action
  loading/*transition*/() {
    return false;
  }

  @action
  error(error, transition) {
    let { to: { params } } = transition;
    if (error instanceof ApiSessionExpiredError || error instanceof AuthRequiredError) {
      const libraryId = params.library_id;
      this.auth.handleAPIAuthDemandError(error, libraryId, transition);
    }

    if (error.status === 401) {
      const applicationSession = this.applicationSession;
      const currentLibraryId = params.library_id;

      applicationSession.clearLibraryToken(currentLibraryId);
      this.reload();
    }

    // Allow error to bubble so error route displays
    return true;
  }

}
