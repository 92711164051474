import ApplicationAdapter from './application';

export default class Article extends ApplicationAdapter {
  handleResponse(status, headers, payload) {
    const unauthorizedError = this.auth.handleUnauthorizedResponse(status, headers, payload);

    if (unauthorizedError) {
      return unauthorizedError;
    }

    if (status === 404) {
      let error = new Error("article not found");
      error.status = 404;
      error.payload = payload;
      return error;
    }

    return super.handleResponse(...arguments);
  }

  urlForQueryRecord(query, modelName) {
    // Use default implementation if the
    // call doesn't specify a DOI or PMID
    if (!query.doi && !query.pmid) {
      return super.urlForQueryRecord(query, modelName);
    }

    // If it does specify a DOI, build the
    // single DOI lookup URL then delete it
    // from the query data so it
    // doesn't affect the queryString
    const host = this.host;
    const prefix = this.urlPrefix();
    let url = [];
    const path = this.pathForType(modelName);

    url.push(path);

    if (query.doi) {
      url.push(encodeURIComponent('doi:' + query.doi));
    }

    if (query.pmid) {
      url.push(encodeURIComponent('pmid:' + query.pmid));
    }

    if (prefix) {
      url.unshift(prefix);
    }

    url = url.join('/');

    if (!host && url && url.charAt(0) !== '/') {
      url = '/' + url;
    }

    delete query.doi;
    delete query.pmid;

    return url;
  }
}
