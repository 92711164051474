import { inject as service } from '@ember/service';
import JSONAPIAdapter from '@ember-data/adapter/json-api';
import config from '../config/environment';
export default class Application extends JSONAPIAdapter {
  @service intl
  @service auth
  @service applicationSession

  namespace = config.apiNamespace
  host = config.apiHost;

  get headers() {
    return {
      ...this.auth.headers,
      ...this.intl.headers
    }
  }

  handleResponse(status, headers, payload) {
    const unauthorizedError = this.auth.handleUnauthorizedResponse(status, headers, payload);

    if (unauthorizedError) {
      return unauthorizedError;
    }

    return super.handleResponse(...arguments);
  }
}
