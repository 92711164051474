import BaseIntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import localeIsSupported from 'libkey-web/utils/locale-is-supported';
import window from 'ember-window-mock';
import { makeArray } from '@ember/array';
import intlPolyfill from 'libkey-web/utils/intl-polyfill';
import libkeyLocales from 'libkey-web/utils/libkey-locales';
import ENV from 'libkey-web/config/environment';
export default class IntlService extends BaseIntlService {
  @service applicationSession
  @service store

  get headers() {
    const primaryLocale = this.primaryLocale || 'en-us';

    return {
      'Accept-Language': primaryLocale
    };
  }

  get browserLocale() {
    return window.testLanguage || navigator.language || navigator.userLanguage;
  }

  async setLocaleFromBrowserLocale() {
    const browserLanguage = (this.browserLocale || '').split('-')[0].toLowerCase();
    let supportedLocale = libkeyLocales.find(locale => locale.code.split('-')[0] === browserLanguage);
    if (supportedLocale) {
      await this.loadAndSetLocale(supportedLocale.code)
    } else {
      await this.loadAndSetLocale('en-us');
    }
  }

  async setLocaleFromSelectedLibrary() {
    const selectedLibrary = this.applicationSession.selectedLibrary;

    if (!selectedLibrary || selectedLibrary === 'unaffiliated') {
      await this.loadAndSetLocale(['en-us']);
      return;
    }

    let library = this.store.peekRecord('library', selectedLibrary);
    if (!library) {
      library = await this.store.findRecord('library', selectedLibrary);
    }

    await this.determineAndSetLocaleFromLibrary(library);
  }

  async determineAndSetLocaleFromLibrary(library) {
    const localeOverride = this.applicationSession.localeOverride;
    const {
      supported: localeOverrideSupported,
      supportedLocale: overrideLocaleMatch,
    } = localeIsSupported(localeOverride, library);

    // If we have a localeOverride, but its not supported by the library,
    // clear it
    if (localeOverride && !localeOverrideSupported) {
      this.applicationSession.set('localeOverride', undefined);
    }

    if (localeOverride && localeOverrideSupported) {
      await this.loadAndSetLocale(overrideLocaleMatch);
    } else {
      // We can't change the navigator.language value in automated tests
      // so first check a property on the window we can set in our test setup
      // so we can simulate having a browser with certain locale settings

      const {
        supported: browserLocaleSupported,
        supportedLocale: browserLocaleMatch,
      } = localeIsSupported(this.browserLocale, library);

      if (browserLocaleSupported) {
        await this.loadAndSetLocale(browserLocaleMatch);
      } else {
        // No locale is set and we can't match the browser's locale with the library's supported locales
        // Just pick amongst the library's supported languages based on the built in language preference order

        if (localeIsSupported('en-us', library)) {
          await this.loadAndSetLocale('en-us');
        } else if (localeIsSupported('fr-fr', library)) {
          await this.loadAndSetLocale('fr-fr');
        } else if (localeIsSupported('ja', library)) {
          await this.loadAndSetLocale('ja');
        } else if (localeIsSupported('de-de', library)) {
          await this.loadAndSetLocale('de-de');
        } else if (localeIsSupported('cy-gb', library)) {
          await this.loadAndSetLocale('cy-gb');
        } else {
          // This should never happen but it did.  Either the back end is broken or the library's configuration is broken.
          // Just set the locale to english as a default.
          await this.loadAndSetLocale('en-us');
        }
      }
    }


    if (!library) {
      return;
    }

    // To be called after locale is set to pick up localized library attribute values
    await this.store.findRecord('library', library.id, { reload: true });
  }

    _loadedTranslations = {}
    async loadTranslations(locale) {
      // Check that translations haven't already been loaded for this locale
      if (!this._loadedTranslations[locale]) {
        const filePath = `translations/${locale}.json`;
        let resource;
        if (['production', 'staging'].includes(ENV.environment)) {
          // In production-like builds, the file names have been hashed to prevent caching issues
          // We need to load the asset map to get the correct file name
          const response = await fetch('/assets/assetMap.json');
          const assetMap = await response.json();

          resource = `${assetMap.prepend ? assetMap.prepend : '/'}${assetMap.assets[filePath]}`;
        } else {
          resource = `/${filePath}`;
        }

        // Load the translations aynschronously
        let response = await fetch(resource);
        let translations = await response.json();
        this._loadedTranslations[locale] = translations;
        this.addTranslations(locale, translations);
      }
    }

    async loadAndSetLocale(locales) {
      for (const locale of makeArray(locales)) {
        // Not all locales are included in all browsers. If the locale is not supported by the browser, we need to polyfill it.
        await intlPolyfill(locale);
        await this.loadTranslations(locale);
      }

      this.setLocale(locales);
    }
}
