import { action } from '@ember/object';
import Controller from '@ember/controller';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ProblematicDomainPageController extends Controller {
  @service applicationSession;

  @tracked leftPanelContentClass;
  @tracked rightPanelCoverClass;

  async performDoubleFade() {
    this.leftPanelContentClass = "fade-out";
    this.rightPanelCoverClass = "fade-in";

    await new Promise((resolve) => {
      later(this, () => {
        return resolve();
      }, 350);
    });
  }

  @action
  fadeOutChangeLibrary() {
    this.applicationSession.selectedLibrary = undefined
    return this.performDoubleFade();
  }

  @action
  hideResponsiveLanguages() {
    document.querySelector('.language-list-component').style.transform = 'translate(0,0)';
    document.querySelector('.language-list-component').classList.add('test-hidden');
    document.querySelector('.page-container').style.transform = 'translate(0,0)';
  }

}

