import errorIsAuthError from './error-is-auth-error';
import responseHasErrorsInTopLevelArray from './response-has-errors-in-top-level-array';
import responseHasAuthErrorWithProxyAvailable from './response-has-auth-error-with-proxy-available';
import responseHasAuthErrorWithSSOGateway from './response-has-auth-error-with-ssogateway';
import responseHasAuthErrorWithVPNAvailable from './response-has-auth-error-with-vpnavailable';
import isJsonApi10ErrorResponse from './is-json-api10-error-response';

/*
  * A 403 indicates the request for a token was rejected.  If preproxy
  * is present, we can retry by routing a request through the proxy.
  * If ipRangeError is present, that means the library has a VPN, so
  * we can instruct the user to connect to it and then try token retrieval
  * again.  Absence of both means we have no means to get a token
  * so all we can do is explain to the user that BrowZine is not available.
  */

export default function responseHasAuthErrorAndNoAuthOptions(errorResponse) {
  // TODO: BZ-4103 Handle both JSON API 1.0
  // and non-standard format until made consistent

  if (responseHasErrorsInTopLevelArray(errorResponse)) {
    const errors = errorResponse.responseJSON;
    return errors.some((error) => errorIsAuthError(error)) &&
      !responseHasAuthErrorWithVPNAvailable(errorResponse) &&
      !responseHasAuthErrorWithProxyAvailable(errorResponse) &&
      !responseHasAuthErrorWithSSOGateway(errorResponse);

  }

  if (isJsonApi10ErrorResponse(errorResponse)) {
    const errors = errorResponse.responseJSON.errors;
    return errors.some((error) => errorIsAuthError(error)) &&
      !responseHasAuthErrorWithVPNAvailable(errorResponse) &&
      !responseHasAuthErrorWithProxyAvailable(errorResponse) &&
      !responseHasAuthErrorWithSSOGateway(errorResponse);
  }

  return errorResponse.responseJSON &&
    errorResponse.responseJSON.status === '403' &&
    errorResponse.responseJSON.title === 'Authentication Failed' &&
    !errorResponse.responseJSON.preproxy &&
    !errorResponse.responseJSON.ipRangeError &&
    !errorResponse.responseJSON.ssoGateway;
}
