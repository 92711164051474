import { Promise, reject } from 'rsvp';
import Evented from '@ember/object/evented';
import Service, { inject as service } from '@ember/service';
import config from '../config/environment';
import fetch from 'fetch';
import mergeAll from 'libkey-web/utils/merge-all';
import window from 'ember-window-mock';
import interpretError from 'libkey-web/utils/interpret-error';
import { run } from '@ember/runloop';
import ApiSessionExpiredError from 'libkey-web/errors/ApiSessionExpiredError';
import AuthRequiredError from 'libkey-web/errors/AuthRequiredError';
import responseHasAuthErrorAndNoAuthOptions from 'libkey-web/utils/response-has-auth-error-and-no-auth-options';
import responseHasAuthErrorWithVPNAvailable from 'libkey-web/utils/response-has-auth-error-with-vpnavailable';
import responseHasAuthErrorWithProxyAvailable from 'libkey-web/utils/response-has-auth-error-with-proxy-available';
import responseHasAuthErrorWithSSOGateway from 'libkey-web/utils/response-has-auth-error-with-ssogateway';
import getPreproxyFromErrorResponse from 'libkey-web/utils/get-preproxy-from-error-response';
import getSSOGatewayFromErrorResponse from 'libkey-web/utils/get-ssogateway-from-error-response';
export default class AuthService extends Service.extend(Evented) {
  @service errorReporter;
  @service analytics;
  @service applicationSession;
  @service flashMessages;
  @service('user-agent') userAgent;
  @service router;
  @service store;

  get headers() {
    const selectedLibraryId = this.applicationSession.selectedLibrary;
    if (selectedLibraryId && selectedLibraryId !== 'unaffiliated') {
      const authHeader = this.getAPIAuthHeaderForLibrary(selectedLibraryId);

      if (authHeader) {
        return {
          'Authorization': authHeader
        }
      }
    }

    return {}
  }

  handleUnauthorizedResponse(status, headers, payload) {
    if (status === 401) {
      return interpretError(status, payload);
    } else if (status === 403) {
      const applicationSession = this.applicationSession;
      const loggedInUser = applicationSession.loggedInUser;
      const token = (loggedInUser || {}).token;
      run(() => {
        this.authenticateUser(null, null, token);
        this.flashMessages.add({
          message: '',
          type: 'alert',
          componentName: 'permissions-error-alert',
          sticky: true,
        });
      });
      return new Error('invalid permissions');
    }
  }

  /**
   * Standardized handler for when API calls come back demanding an auth credential
   * either because the submitted one has expired, or one was missing altogether
   *
   * Intended to be called from the error hook of routes
   *
   * @param {Error} err - The error object representing the error
   * @param {String} libraryId - The id of the library that owns the data we need a credential authorizing access to
   * @param {Object} transition - The transition object representing the transition that was in progress when the error happened
   *
   */
  handleAPIAuthDemandError(err, libraryId, transition) {
    const sessionHasExpired = err instanceof ApiSessionExpiredError;

    if (sessionHasExpired || err instanceof AuthRequiredError) {
      return this.attemptDirectLibraryAuth(libraryId, false, transition)
        .then(() => {
          return transition.retry();
        }, (err) => {
          if (err.name === 'TransitionAborted') {
            // These are expected as part of the retry (I think).  Don't need to do anything on them
            return;
          }

          // All the supporting error methods below are expecting a payload that looks like a jquery response payload
          // and instead of refactoring them all, let's just give them what they expect
          err = { responseJSON: JSON.parse(err.message) }

          if (responseHasAuthErrorAndNoAuthOptions(err)) {
            return this.router.transitionTo('library.unavailable', libraryId, {queryParams: {context: sessionHasExpired ? 'Expired Session' : undefined}});

          } else if (responseHasAuthErrorWithVPNAvailable(err)) {
            // Save what we were trying to do and send the user to a route instructing
            // them to connect to their VPN
            this.applicationSession.set('transitionToRetryAfterAuth', transition);
            return this.router.transitionTo('library.vpn-required', libraryId, {queryParams: {context: sessionHasExpired ? 'Expired Session' : undefined}});
          } else if (responseHasAuthErrorWithSSOGateway(err)) {
            // The direct auth attempt should have encoded the original transition into the success path send
            // to the API enpoint, so it should be encoded in the "preproxy" URL sent back  by the API so that
            // after proxy authentication we can decode it and send the user to whatever they were originally
            // trying to do.
            const ssoGateway = getSSOGatewayFromErrorResponse(err);
            return this.authenticateLibraryThroughSSOGateway(ssoGateway, transition);
          } else if (responseHasAuthErrorWithProxyAvailable(err)) {
            // The direct auth attempt should have encoded the original transition into the success path send
            // to the API enpoint, so it should be encoded in the "preproxy" URL sent back  by the API so that
            // after proxy authentication we can decode it and send the user to whatever they were originally
            // trying to do.
            const preproxyUrl = getPreproxyFromErrorResponse(err);
            return this.authenticateLibraryThroughProxy(preproxyUrl, transition);
          }

          // Something like a 503 on the tokens endpoint would fall down to here.
          // TODO: BZ-4104
          // The above branches should exhaust the possibilities
          // log a warning if the code actually reaches here
          console.warn(`
          Direct auth failed for unknown reason, could not detect how to handle failure
          error: ${err.stack}`);
      });
    }
  }

  getAPIAuthHeaderForLibrary(libraryId) {
    const libraryTokenData = this.applicationSession.lookupLibraryToken(libraryId);

    if (!libraryTokenData || !libraryTokenData.token) {
      return;
    }

    const loggedInUser = this.applicationSession.loggedInUser;

    if (loggedInUser && loggedInUser.token) {
      return `Bearer ${libraryTokenData.token}|${loggedInUser.token}`;
    } else {
      return `Bearer ${libraryTokenData.token}`;
    }
  }

  authenticateUser(email, password, existingUserToken) {
    return new Promise((resolve, reject) => {
      fetch(`${config.apiBaseUrl}/api-tokens`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
          email: email,
          password: password,
          existingToken: existingUserToken
        })
      })
      .then(response => {
        if (!response.ok) {
          return response.json().then(errResponse => {
            // Fetch promises only reject on network errors, not on 4xx or 5xx errors
            // Throwing error here for .catch handler to handle
            throw new Error(JSON.stringify(errResponse));
          });
        }
        return response.json();
      })
      .then((response) => {
        let values = response['api-tokens'][0];
        let token = values.id;

        const permissionGrants = (values.permission_grants || []).map((grant) => {
          return {
        permissionCode: grant.permission_code,
        libraryScope: grant.library_scope
          };
        });

        resolve({
          userId: values.links.user.id,
          email: values.links.user.email,
          permissionGrants,
          couchdbDatabaseLocation: values.links.user.couchdbDatabaseLocation || config.couchDatabase,
          token: token
        });
      })
      .catch((err) => {
        let reason = null;

        if (err && err.responseJSON) {
          reason = this.getErrorObjectFromErrorResponse(err.responseJSON);
        }

        if (!reason) {
          reason = "unknown error";
        }

        reject(reason);
      });
    })
    .then((userData) => {
      this.applicationSession.setLoggedInUser(userData);
    });

  }

  /**
   * For libraries that operate a proxy, authentication
   * is liable to be a 2-step process.  We always first
   * attempt to retrieve a token via a simple AJAX call against
   * the api-tokens endpoint.  If the machine is connected to
   * the library's network, then the request simply comes back
   * with a token.
   *
   * If the library is not configured as a secure library, the
   * behavior off-site depends upon the value of the forceAuth
   * parameter and the library's "proxyFrontdoorAccess" configuration
   * value.  When forceAuth: false is sent, requests should come
   * back with a new token.  If forceAuth: true is sent, however,
   * and the proxyFrontdoorAccess configuration value is also true,
   * a token should only come back if the client is on the library's
   * network.  When off-site, it should respond with either a preproxy
   * URL informing the client of how to route traffic to the endpoint
   * through the library's proxy, or an ipRangeError indicating the
   * library has a VPN that should be connected to.  When
   * proxyFrontdoorAccess is false, however, the endpoint should just
   * repond with a token.
   *
   * Note: "ProxyFrontdoorAccess" is kind a of a misnomer for the setting
   * as the back-end does not know whether the request is part of
   * displaying the application's front door or not.  These names should
   * probably be adjusted to more clearly fit what they actually do now.
   * (Perhaps optIntoAuth && disregardAuthOptIn ??)
   * TODO: BZ-4089
   *
   * If the machine accessing BZWeb is off of the library's network
   * and the library is set up to require auth, then the request
   * should respond with a 4xx error (401 if the machine is outside
   * all customer networks, 403 if the machine is within the network
   * of a different customer than the one whose library they're
   * requesting a token for), and in the body of the 4xx response,
   * the API endpoint should provide a url that BZWeb can redirect its
   * window to in order to send the user through the authentication
   * flow to acquire a token by sending the traffic through the school's
   * proxy.  If the user's browser does not already have a session
   * established with the customer's proxy, this flow would include
   * displaying the user the proxy's login form.  Once the user's browser
   * can send traffic through the customer's proxy, the provided url
   * should send a request to back-end that requests a page that serves
   * an auto-posting form that posts against the api-token endpoint
   * with options that cause the back-end to redirect back to BZWeb's
   * accept-token route, with the authorized bearer token passed
   * to the route via a querystring parameter named "token"
   */


  attemptDirectLibraryAuth(libraryId, forceAuth, transitionToResumeAfterAuth) {
    // This is a workaround for when the router is not present in tests
    let successPath = '/';
    let failurePath = '/';

    let router = this.router?._router;

    if (router) {
      let queryParams = {};
      const transitionToRetry = transitionToResumeAfterAuth;
      // If we were mid-transition, encode the intent so it can be retrieved on the other end of authentication
      // We're stuck doing this if proxies are going to change our domain mid-session
      if (transitionToRetry && transitionToRetry.intent) {

        let intentUrl;

        // Sometimes a transition's intent has a url on it, sometimes it doesn't!
        if (transitionToRetry.intent.url) {
          intentUrl = transitionToRetry.intent.url;
        } else {

          // If the intent doesn't explicitly hold a URL, use the route-recognizer (https://github.com/tildeio/route-recognizer)
          // held onto by the Ember router to get at a URL that represents where the transition was trying to take the user.

          const allParams = Object.getOwnPropertyNames(transitionToRetry.to.params).map((key) => transitionToRetry.to.params[key]);
          const generatedUrl = router.generate(transitionToRetry.intent.name, mergeAll({}, allParams));

          intentUrl = generatedUrl;

          // If there were queryParams on the intent add them to the generated URL

          if (transitionToRetry.to.queryParams && Object.getOwnPropertyNames(transitionToRetry.to.queryParams).length) {
            const queryString = Object.getOwnPropertyNames(transitionToRetry.to.queryParams)
              .map((parameterName) => parameterName + '=' + transitionToRetry.to.queryParams[parameterName])
              .join('&');

            intentUrl += '?' + queryString;
          }
       }

        queryParams.intent = window.btoa(JSON.stringify({ url: intentUrl }));
      }

      successPath = router.generate('accept-token', libraryId, { queryParams: queryParams});
      failurePath = router.generate('token-failure', libraryId);
    }

    return new Promise((resolve, reject) => {
      fetch(`${config.apiBaseUrl}/api-tokens`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
          libraryId,
          returnPreproxy: true,
          client: 'bzweb',
          forceAuth: forceAuth,
          success: successPath,
          failure: failurePath
        })
      })
      .then(response => {
        if (!response.ok) {
          return response.json().then(errResponse => {
            // Fetch promises only reject on network errors, not on 4xx or 5xx errors
            // Throwing error here for .catch handler to handle
            throw new Error(JSON.stringify(errResponse));
          });
        }
        return response.json();
      })
      .then((response) => {
        let values = response['api-tokens'][0];
        let token = values.id;

        resolve({
          libraryId: libraryId,
          token: token,
          expiresAt: values.expires_at
        });
      })
      .catch((err) => {
        reject(err);
      });
    })
    .then((libraryTokenData) => {
      const applicationSession = this.applicationSession;

      applicationSession.storeTokenForLibrary(libraryTokenData.libraryId, { token: libraryTokenData.token, expiresAt: libraryTokenData.expiresAt });
      applicationSession.set('selectedLibrary', libraryId);
    });
  }

  authenticateLibraryDirectOnly(libraryId) {
    return new Promise((resolve, reject) => {
      fetch(`${config.apiBaseUrl}/api-tokens`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
          libraryId,
          returnPreproxy: true,
        })
      })
      .then(response => {
        if (!response.ok) {
          return response.json().then(errResponse => {
            // Fetch promises only reject on network errors, not on 4xx or 5xx errors
            // Throwing error here for .catch handler to handle
            throw new Error(JSON.stringify(errResponse));
          });
        }
        return response.json();
      })
      .then((res) => {
        let responseValue = res['api-tokens'][0];

        resolve({
          libraryId: libraryId,
          token: responseValue.id,
          expiresAt: responseValue.expires_at
        });
      })
      .catch((err) => {
        reject(err);
      });
    })
    .then((libraryTokenData) => {
      const applicationSession = this.applicationSession;

      applicationSession.storeTokenForLibrary(libraryTokenData.libraryId, { token: libraryTokenData.token, expiresAt: libraryTokenData.expiresAt });
      applicationSession.set('selectedLibrary', libraryId);
    });
  }

  authenticateLibraryThroughProxy(preproxyUrl, currentTransition) {

    window.location.assign(preproxyUrl);

    if (currentTransition) {
      // If we got a currentTransition, abort it so no more route hooks run
      currentTransition.abort();
    }
    // Signal to the caller that the window's location
    // has been updated so it can avoid
    // rendering any new HTML
    return { updatedWindowLocation: true };
  }

  authenticateLibraryThroughSSOGateway(ssoGateway, currentTransition) {
    window.location.assign(ssoGateway);

    if (currentTransition) {
      // If we got a currentTransition abort it so no more route hooks run
      currentTransition.abort();
    }
    // Signal to the caller that the window's location
    // has been updated so it can avoid
    // rendering any new HTML
    return { updatedWindowLocation: true };
  }

  authenticateLibrary(options, transitionToResumeAfterAuth) {

    let libraryId = options.libraryId,
      forceAuth = options.forceAuth;

    return this.attemptDirectLibraryAuth(libraryId, forceAuth, transitionToResumeAfterAuth)
      .catch((err) => {
        let reason = null;

        if (err && err.responseJSON) {
          reason = this.getErrorObjectFromErrorResponse(err.responseJSON);
        }

        if (!reason) {
          reason = "unknown error";
        }

        if (reason.preproxy) {
          return this.authenticateLibraryThroughProxy(reason.preproxy, transitionToResumeAfterAuth);
        }

        if (reason.ssoGateway) {
          return this.authenticateLibraryThroughSSOGateway(reason.ssoGateway, transitionToResumeAfterAuth);
        }

        return reject(reason);
      });

  }

  signUpUser(email, password, deviceId, showBpsMessage) {
    let options = {
      showBpsMessage: showBpsMessage,
      selectedLibrary: this.applicationSession.selectedLibrary
    };

    for (var option in options) {
      if (Object.prototype.hasOwnProperty.call(options, option)) {
        if (!options[option]) {
          delete options[option];
        }
      }
    }

    let queryParams = new URLSearchParams(options).toString();

    return this._authFetch(`${config.apiBaseUrl}/user-signups`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password,
        confirm_url_template: `${window.location.protocol}//${window.location.host}/confirm-email/{email_confirmation_token}/?${queryParams}`,
        device_id: deviceId
      })
    })
    .then(response => {
      return response.json().then(json => {
        return json['user-signups'][0];
      });
    });
  }

  getUserSignupStatus(id) {
      return this._authFetch(`${config.apiBaseUrl}/user-signups/${id}`, {
        headers: { 'Accept': 'application/json' }
      })
      .then(response => {
        return response.json().then(json => {
          return json['user-signups'][0];
        });
      });
  }

  awaitSignupConfirmation(confirmationId) {
    return this.getUserSignupStatus(confirmationId).then(signup => {
      if (!signup.email_confirmed) {
        //recursive call with 1s delay
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            try {
              resolve(this.awaitSignupConfirmation(confirmationId));
            } catch (err) {
              reject(err);
            }
          }, 1000);
        });
      } else {
        return signup;
      }
    });
  }

  awaitSignupConfirmationAndAuthenticate(confirmationId, email, password) {
    return this.awaitSignupConfirmation(confirmationId).then(() => {
      return this.authenticateUser(email, password);
    });
  }

  confirmEmail(token) {
    return this._authFetch(`${config.apiBaseUrl}/user-signup-confirmations/${token}`, {
      method: 'put',
      headers: {
        'Accept': 'application/json',
      }
    })
    .then(response => response.json())
    .then(async (responseJSON) => {
      //count the user once they are confirmed
      const analytics = this.analytics;
      const applicationSession = this.applicationSession;

        await analytics.recordEvent({
          category: "AccountSignup",
          action: "BzAccountVerified",
          label: this.userAgent.platform,
          value: applicationSession.selectedLibrary || "no-selected-library",
        });

      //authenticate the user once they are confirmed
      const confirmation = responseJSON['user-signup-confirmations'][0];
      // The response calls this "api-token" but we should probably
      // have called it "user-token" to keep it distinct from the tokens
      // used to identify association with a particular customer library.
      const token = confirmation.links['api-token'];
      const user = confirmation.links['api-token'].links.user;

      // The API endpoint that confirms user signup emails also starts a
      // user login session for the user whose signup it completes.  The
      // response contains a bearer token that the app can use to act on
      // behalf of that user, and also contains information about the user
      // account (like specific permissions granted, the location of
      // the user's couchDB database, etc.)
      //
      // Stick that in the Ember app's client side session, and consider
      // the user logged in within that session.

      const userAuthInfo = {
        userId: user.id,
        email: user.email,
        permissions: token.permissions,
        couchdbDatabaseLocation: user.couchdbDatabaseLocation || config.couchDatabase,
        token: token.id
      };

      this.applicationSession.setLoggedInUser(userAuthInfo);
    });
  }

  emailResetToken(email) {
    return this._authFetch(`${config.apiBaseUrl}/users/password-resets`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        reset_url_template:  `${window.location.protocol}//${window.location.host}/auth/reset?token={password_reset_token}&email={email}`
      })
    })
    .then(() => true);
  }

  updatePassword({email, token, newPassword}) {
    return this._authFetch(`${config.apiBaseUrl}/users/change-password`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        reset_token: token,
        new_password: newPassword
      })
    }).then(() => true);
  }

  validatePassword(password, confirm) {
    return Promise.resolve().then(() => {
      if (!password || !password.length) {
        throw new Error('Please enter a password.');
      }

      if (password.length < 8) {
        throw new Error('Passwords must be at least 8 characters.');
      }

      if (password !== confirm) {
        throw new Error('Passwords must match.');
      }

      return password;
    });
  }

  /*
   * Checks if the specified library uses BPS
   * so the login and signup flows gets configured
   * with appropriate messaging and behavior for BPS libraries.
   *
   */
  checkIfLibraryUsesBPS(libraryId) {
    const store = this.store;

    if (!libraryId) {
      return Promise.resolve(false);
    } else {
      return store.findRecord('library', libraryId).then(lib => {
        return lib.bpsLibrary;
      });
    }
  }

  authenticateBps(libraryId) {
    let userToken;
    const loggedInUser = this.applicationSession.loggedInUser;

    if (loggedInUser) {
      userToken = loggedInUser.token;
    }

    return new Promise((resolve, reject) => {
      fetch(`${config.apiBaseUrl}/api-tokens`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
          libraryId,
          bpsAuth: true,
          userToken: userToken
        })
      })
      .then(response => response.json())
      .then((response) => {
        let values = response['api-tokens'][0];
        let token = values.id;

        resolve({
          libraryId: libraryId,
          token: token,
          expiresAt: values.expires_at
        });
      })
      .catch((err) => {
        let reason = null;

        if (err && err.responseJSON) {
          reason = this.getErrorObjectFromErrorResponse(err.responseJSON);
        }

        if (!reason) {
          reason = "unknown error";
        }

        reject(reason);
      });
    });


  }

  sendBpsEmail(libraryId) {
    let userToken;
    const loggedInUser = this.applicationSession.loggedInUser;

    if (loggedInUser) {
      userToken = loggedInUser.token;
    }

    return this._authFetch(`${config.apiBaseUrl}/bps-confirmations`, {
          method: "post",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userToken,
            libraryId,
            confirm_url_template: `${window.location.protocol}//${window.location.host}/auth/login?confirm-bps-email=true&library-id={library_id}`,
          })
        })
        .then(response => response.json())
        .then(json => json['bps-confirmations'][0]);
  }

  _authFetch(url, options) {
    return new Promise((resolve, reject) => {
      return fetch(url, options)
      .then(response => {
        if (response.status === 200) {
          return response;
        } else if (response.status === 422) {
          return response.json().then(json => {
            var jsonError = this.getErrorObjectFromErrorResponse(json[0]);
            if (jsonError && jsonError.userDetail) {
              var userErr = new Error(jsonError.userDetail);
              userErr.isUserErr = true;
              var errData = jsonError.userData;
              if (errData) {
                Object.keys(errData).forEach(key => userErr[key] = errData[key]);
              }
              throw userErr;
            } else {
              throw new Error(`Unknown error response from server: ${JSON.stringify(json)}`);
            }
          });
        } else {
          throw new Error(`Invalid status code: ${response.status}`);
        }
      })
      .then((result) => {
        resolve(result);
      })
      .catch(err => {
        if (err.isUserErr) {
          throw err;
        }
        this.errorReporter.reportUnknownError(`error performing auth fetch for url: ${url}`, err);
        throw new Error("An unknown error occurred, please try again.");
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  // The back-end is transitioning from an old format where
  // the error object is the top level object to one that
  // fits the JSON API error response spec, where the error object
  // is in an array held by the errors property of the top level
  // object.  This makes BZWeb forward-compatible with the
  // upcoming new format.
  getErrorObjectFromErrorResponse(errorResponse) {
    if (errorResponse.errors && errorResponse.errors.length) {
      return errorResponse.errors[0];
    }

    if (errorResponse.length) {
      return errorResponse[0];
    }

    return errorResponse;
  }
}
