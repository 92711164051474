import { inject as service } from "@ember/service";
import Route from "@ember/routing/route";
import { scheduleOnce } from "@ember/runloop";
export default class ChooseLibraryNoArticleRoute extends Route {
  @service applicationSession;
  @service store;
  @service intl;
  @service auth;
  @service analytics;

  async beforeModel() {
    const analytics = this.analytics;
    analytics.setProductDimension('LibkeyIO');
    await this.handleLocaleOnLibraryChooserRoute();
  }

  async model() {
    const applicationSession = this.applicationSession;
    const holdingsToolLibraryGroup = applicationSession.holdingsToolLibraryGroup;
    let libraries;
    if (holdingsToolLibraryGroup) {
      libraries = await this.store.query("library", {
        "filter[library_group_id]": holdingsToolLibraryGroup?.id,
      });
    } else {
      libraries = await this.store.findAll('library');
      libraries = libraries.filter((library) => {
        const subscriptions = library.subscriptions;
        return subscriptions.libkey && subscriptions.libkey.active;
      });
    }
    return {
      selectableLibraries: libraries,
      holdingsToolLibraryGroup
    };
  }

  setupController(controller, model) {
    if (this.applicationSession.transitioningFromLandingPage) {
      controller.set('leftPanelContentClass', 'invisible');
    }

    controller.set("selectableLibraries", model.selectableLibraries);
    controller.set("holdingsToolLibraryGroup", model.holdingsToolLibraryGroup);

    // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
    scheduleOnce("afterRender", this, () => {
      if (!controller.isDestroyed) {
        controller.set("leftPanelContentClass", "fade-in");
      }
    });
  }

  async handleLocaleOnLibraryChooserRoute() {
    // the library chooser routes are a little different in that we localize them despite the user
    // being unaffiliated so that we don't get awkward flipping between english and a local language
    const applicationSession = this.applicationSession;
    const localeOverride = applicationSession.localeOverride;

    if (localeOverride) {
      await this.intl.loadAndSetLocale(localeOverride);
      return;
    }

    await this.intl.setLocaleFromBrowserLocale();
  }
}
