import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';


export default class ProblematicDomainPanel extends Component {
  @service analytics;
  @service intl;

    get problematicDomainWatermarkImageUrl() {
      const currentLocale = this.intl.primaryLocale;
      if (currentLocale == 'fr-fr') {
        return '/images/problematic-domain-fr.png';
      } else if (currentLocale == 'ja') {
        return '/images/problematic-domain-ja.png';
      } else if (currentLocale == 'de-de') {
        return '/images/problematic-domain-de.png';
      } else if (currentLocale == 'cy-gb') {
        return '/images/problematic-domain-cy.png';
      } else {
        return '/images/problematic-domain.png';
      }
    }


  @action
  async didInsert() {
    if (!!this.args.library && !!this.args.domain) {
      await this.analytics.recordEvent({
        category: 'problematicDomainNoticeViewed',
        action: 'NoticeViewed',
        label: '',
        value: this.args.domain.id
      });
    }
  }
}

