import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import config from '../config/environment';
import ApiSessionExpiredError from '../errors/ApiSessionExpiredError';
import AuthRequiredError from '../errors/AuthRequiredError';
import getRedirectURLIfProxying from 'libkey-web/utils/get-redirect-url-if-proxying';
import getMatchingHoldingsToolSubdomain from 'libkey-web/utils/get-matching-holdings-tool-subdomain';
import window from 'ember-window-mock';

export default class ApplicationRoute extends Route {
  @service flashMessages;
  @service errorReporter;
  @service applicationSession;
  @service auth;
  @service intl;
  @service media;
  @service analytics;
  @service router;
  @service store;

  async beforeModel(transition) {
    let { to: { queryParams, params, name: targetName } } = transition;
    this._setAnalyticsDimensions(queryParams);
    this._handleMobileViewport();
    await this._setHoldingsToolLibraryGroup();

    let libraryId = this._determineLibraryId(queryParams, params);
    let library   = await this._loadLibraryRecord(libraryId);
    if (library) {
      await this._fireOpenedEvent(libraryId, targetName);
      this.applicationSession.set('selectedLibrary', libraryId);
      await this.intl.setLocaleFromSelectedLibrary();
    } else {
      await this._fireOpenedEvent(null, targetName);
      await this._handleNoLibrarySelection();
      await this.intl.loadAndSetLocale(['en-us']);
    }
  }

  redirect() {
    const redirectURL = getRedirectURLIfProxying({
      windowLocationURL: window.location,
      baseURLForProxyComparison: config.baseURLForProxyComparison,
      holdingsToolSubdomains: config.holdingsToolSubdomains
    });

    if (redirectURL) {
      window.location = redirectURL;
    }
  }

  @action
  loading() {
    document.body.style.backgroundColor = "#2c2c4e";
    return true
  }

  @action
  error(error, transition) {
    const sessionHasExpired = error instanceof ApiSessionExpiredError;
    if (sessionHasExpired || error instanceof AuthRequiredError) {
      const selectedLibrary = this.applicationSession.selectedLibrary;
      if (selectedLibrary) {
        return this.auth.handleAPIAuthDemandError(error, selectedLibrary, transition);
      } else {

        // Somehow a route slipped out of development that makes API calls
        // that require auth, but can have no library selected.  This
        // shouldn't happen, we should be handling this case in the specific route
        // If somehow an error bubbled up here and no library is selected,
        // then let the code fall through to the code below where its logged
        // and then user is sent to the error route

        console.warn('Received unexpected Auth demand: API demands auth, but no library is selected');
      }
    }

    //transition.intent.url seems to be the only way to find the previously-visited URL,
    //  and it seems to not be in the public API, so want to access it and protect against
    //  any future changes that may make this undefined
    if (transition && transition.intent && transition.intent.url) {
      error.lastTransitionUrl = transition.intent.url;
    }

    try {
      this.errorReporter.reportUnknownError("error transitioning to " + transition.to.name, error);
    } catch(err) {
      console.error(err); //unlikely event, an error while reporting the error
    }

    // Clear style from loading substate if its still set before going to error route
    document.body.style.backgroundColor = '';
    return true; //continue bubbling to error route
  }

  title(tokens) {
    return `${tokens.reverse().join(' — ')}${tokens.length > 0 ? ' — ' : ''}LibKey`;
  }

  async _fireOpenedEvent(libraryId, routeName) {
    if ( libraryId && libraryId !== 'unaffiliated') {
      this.analytics.setCustomDimension(1, libraryId);
    }

    await this.analytics.recordEvent({
      category: 'Activity',
      action: 'Opened',
      routeName: routeName
    });
  }

  _handleMobileViewport() {
    if (this.media.isMobile || this.media.isTabletportrait) {
      document.body.style.overflowX = 'hidden';
    }
  }

  _rememberLibrarySelection() {
    const selectedLibrary = this.applicationSession.selectedLibrary;
    this.applicationSession.set('originalSelectedLibrary', selectedLibrary);
  }

  _revertLibrarySelection() {
    const originalSelectedLibrary = this.applicationSession.get('originalSelectedLibrary');
    this.applicationSession.set('selectedLibrary', originalSelectedLibrary);
1  }

  _determineLibraryId(queryParams, params) {
    if (this.applicationSession.selectedLibrary && this.applicationSession.selectedLibrary !== 'unaffiliated') {
      return this.applicationSession.selectedLibrary;
    }

    // If we haven't figured out the selected library yet, try to figure it out from the route parameters
    const libraryParams = Object.values(params).find(({ library_id }) => library_id) || {};
    return queryParams.selectedLibrary || libraryParams.library_id;
  }

  async _handleNoLibrarySelection() {
    if (config.environment !== 'test') {
      console.warn('Could not associate window with a selected library from query parameters or route parameters.');
    }
  }

  async _loadLibraryRecord(libraryId) {
    this._rememberLibrarySelection();
    try {
      if (!libraryId) return;
      let library = await this.store.findRecord('library', libraryId);
      return library;
    } catch (error) {
      this._revertLibrarySelection();
    }
  }

  _setAnalyticsDimensions(queryParams) {
    // Detect if this is the user first arriving at libkey
    const firstTransition = !this.applicationSession._anyTransitionRun;
    this.applicationSession.set('_anyTransitionRun', true);

    if (firstTransition) {
      this.analytics.setUTMDimension('utm_source', queryParams.utm_source);
      this.analytics.setUTMDimension('utm_medium', queryParams.utm_medium);
      this.analytics.setUTMDimension('utm_campaign', queryParams.utm_campaign);
      this.analytics.setUTMDimension('utm_term', queryParams.utm_term);
      this.analytics.setUTMDimension('utm_content', queryParams.utm_content);
    }
  }

  async _setHoldingsToolLibraryGroup() {
    if (config.holdingsToolSubdomains) {
      const matchingHoldingsToolSubdomain = getMatchingHoldingsToolSubdomain(
        window.location.hostname,
        config.holdingsToolSubdomains.split(",")
      );

      if (matchingHoldingsToolSubdomain) {
        const libraryGroups = await this.store.query("library-group", {
          "filter[holdings_tool_subdomain]": matchingHoldingsToolSubdomain,
        });
        const libraryGroup = libraryGroups?.[0];
        this.applicationSession.set("holdingsToolLibraryGroup", libraryGroup);
        this.analytics.setProductDimension("HoldingsTool");
      }
    }
  }
}
