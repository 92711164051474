import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { findDoiInInput } from '../utils/doi-utils';
import { findPmidInInput } from '../utils/pmid-utils';

export default class ArticleOrPmidRoute extends Route {
  @service analytics;
  @service applicationSession;
  @service unpaywall;
  @service router;
  @service store;
  @service auth;

  beforeModel(transition) {
    let { to: { params, queryParams } } = transition;

    const analytics = this.analytics;
    const applicationSession = this.applicationSession;

    if (queryParams.disableRedirect) {
      this.disableRedirect = true;
    } else {
      this.disableRedirect = false;
    }

    const libraryId = applicationSession.selectedLibrary;

    if (
      queryParams.utm_source &&
      queryParams.utm_source.toLowerCase().includes("api_")
    ) {
      analytics.setProductDimension("LibkeyAPI");
      this.libkeyProduct = "LibkeyAPI";
    } else if (
      queryParams.utm_source &&
      queryParams.utm_source.toLowerCase() === "nomad"
    ) {
      analytics.setProductDimension("LibkeyNomad");
      this.libkeyProduct = "LibkeyNomad";
    } else {
      analytics.setProductDimension("LibkeyIO");
      this.libkeyProduct = "LibkeyIO";
    }

    let requestedId = params.id_value;

    const doiResult = findDoiInInput(requestedId);
    const pmidResult = findPmidInInput(requestedId);

    if (doiResult.foundDoi && requestedId !== doiResult.doi) {
      requestedId = doiResult.doi;
    } else if (!doiResult.foundDoi && pmidResult.foundPmid && requestedId !== pmidResult.pmid) {
      requestedId = pmidResult.pmid;
    }

    if (libraryId) {
      return this.router.transitionTo('wayfless-article-or-pmid', libraryId, requestedId);
    } else {
      return this.router.transitionTo('choose-library', requestedId);
    }
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.set('libkeyProduct', this.libkeyProduct);
  }
}
