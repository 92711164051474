import { shouldPolyfill as shouldPolyfillDateTimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill';

const intlPolyfill = async function (locale) {
  let needsPolyfilling = shouldPolyfillLocale() ||
                         shouldPolyfillNumberFormat(locale) ||
                         shouldPolyfillPluralRules(locale) ||
                         shouldPolyfillRelativeTimeFormat(locale) ||
                         shouldPolyfillDateTimeFormat(locale)

  if (!needsPolyfilling) {
    return;
  }

  if (shouldPolyfillLocale()) {
    await import('@formatjs/intl-locale/polyfill-force');
  }

  if (shouldPolyfillPluralRules(locale)) {
    await import('@formatjs/intl-pluralrules/polyfill-force');
  }

  if (shouldPolyfillRelativeTimeFormat(locale)) {
    await import('@formatjs/intl-relativetimeformat/polyfill-force');
  }

  if (shouldPolyfillNumberFormat(locale)) {
    await import('@formatjs/intl-numberformat/polyfill-force');
  }

  if (shouldPolyfillDateTimeFormat(locale)) {
    await import('@formatjs/intl-datetimeformat/polyfill-force');
  }

  const localeBase = locale.toLowerCase().split('-')[0];

  switch (localeBase) {
    case 'en':
      await import('@formatjs/intl-pluralrules/locale-data/en');
      await import('@formatjs/intl-relativetimeformat/locale-data/en');
      await import('@formatjs/intl-numberformat/locale-data/en');
      await import('@formatjs/intl-datetimeformat/locale-data/en');
      break;
    case 'fr':
      await import('@formatjs/intl-pluralrules/locale-data/fr');
      await import('@formatjs/intl-relativetimeformat/locale-data/fr');
      await import('@formatjs/intl-numberformat/locale-data/fr');
      await import('@formatjs/intl-datetimeformat/locale-data/fr');
      break;
    case 'de':
      await import('@formatjs/intl-pluralrules/locale-data/de');
      await import('@formatjs/intl-relativetimeformat/locale-data/de');
      await import('@formatjs/intl-numberformat/locale-data/de');
      await import('@formatjs/intl-datetimeformat/locale-data/de');
      break;
    case 'ja':
      await import('@formatjs/intl-pluralrules/locale-data/ja');
      await import('@formatjs/intl-relativetimeformat/locale-data/ja');
      await import('@formatjs/intl-numberformat/locale-data/ja');
      await import('@formatjs/intl-datetimeformat/locale-data/ja');
      break;
    case 'cy':
      await import('@formatjs/intl-pluralrules/locale-data/cy');
      await import('@formatjs/intl-relativetimeformat/locale-data/cy');
      await import('@formatjs/intl-numberformat/locale-data/cy');
      await import('@formatjs/intl-datetimeformat/locale-data/cy');
      break;
    default:
      await import('@formatjs/intl-pluralrules/locale-data/en');
      await import('@formatjs/intl-relativetimeformat/locale-data/en');
      await import('@formatjs/intl-numberformat/locale-data/en');
      await import('@formatjs/intl-datetimeformat/locale-data/en');
      break
  }
};

export default intlPolyfill;
