import Model, { belongsTo, attr, hasMany } from "@ember-data/model";

export default class Issue extends Model {
  @belongsTo('library', { async: true , inverse: 'issues' })
  library;

  @belongsTo('journal', { async: true, inverse: 'issues' })
  journal;

  @attr('string')
  title;

  get shortTitle() {
    let title = this.title;
    return title.replace('Vol.', 'Vol');
  }

  // This is a `string` rather than a `date` to bypass the default parsing that
  // assumes UTC. Could instead be solved with an overridden serialiser.
  @attr('string')
  date;

  get year() {
    var date = this.date;
    return parseInt(date.substr(0, 4), 10);
  }

  // If an issue is unavailable at the library
  // the back end provides a message why in this property
  @attr('string')
  availabilityMessage;

  @attr('boolean')
  withinSubscription;

  @attr('boolean')
  embargoed;

  @attr('boolean')
  suppressed;

  @hasMany('article', { async: true, inverse: 'issue' })
  articles;

  @attr('boolean')
  isValidIssue;
}
