import responseHasErrorsInTopLevelArray from "./response-has-errors-in-top-level-array";
import isJsonApi10ErrorResponse from "./is-json-api10-error-response";
import errorIsAuthErrorWithSSOGateway from "./error-is-auth-error-with-ssogateway";
import _ from "lodash";

export default function getSSOGatewayFromErrorResponse(errorResponse) {
  // TODO: BZ-4103 Handle both JSON API 1.0
  // and non-standard formats until made constistent

  if (responseHasErrorsInTopLevelArray(errorResponse)) {
    const errorWithSSOGateway = _.find(errorResponse.responseJSON, errorIsAuthErrorWithSSOGateway);
    return errorWithSSOGateway.ssoGateway;
  }

  if (isJsonApi10ErrorResponse(errorResponse)) {
    const errorWithSSOGateway = _.find(errorResponse.responseJSON.errors, errorIsAuthErrorWithSSOGateway);

    return errorWithSSOGateway.ssoGateway;
  }

  return errorResponse.responseJSON.ssoGateway;
}
