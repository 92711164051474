import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { next } from '@ember/runloop';
import { waitForPromise } from '@ember/test-waiters';
import libkeyLocales from 'libkey-web/utils/libkey-locales';

export default class LanguageList extends Component {
  @service applicationSession;
  @service intl;
  @service media;

  languageOptions = libkeyLocales

  constructor() {
    super(...arguments);

    const handleKeyPress = ((event) => {
      //Esc Key
      if(event.keyCode === 27) {
        next(() => {
          this.hide();
        });
      }
    });

    document.addEventListener('keyup', handleKeyPress);
  }

  @action
  languageIsSupported(language) {
    const library = this.args.library;
    if (!library) {
      return false;
    }
    const languagesSupported = (library.languagesSupported || '').split(',');
    return languagesSupported.some((lang) => lang === language);
  }

  @action
  hide() {
    if (this.media.isMobile || this.media.isTabletportrait) {
      this.args.hideResponsiveLanguages();
    } else {
      this.args.hideLanguages();
    }
  }

  @action
  async selectLanguage(language) {
    const currentLocale = this.intl.primaryLocale;
    if (language === currentLocale) {
      if (this.media.isMobile || this.media.isTabletportrait) {
        this.args.hideResponsiveLanguages();
      } else {
        this.args.hideLanguages();
      }
      return;
    }

    this.applicationSession.set('localeOverride', language);

    // we need wait for promise here so the tests know to wait for this async action to complete
    await waitForPromise(this.intl.loadAndSetLocale(language));
    if (this.media.isMobile || this.media.isTabletportrait) {
      this.args.hideResponsiveLanguages();
    }
    this.hide();
    this.args.onSelectLanguage();
  }
}
