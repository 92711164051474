import { isEmpty } from "@ember/utils";
import Model, { attr, belongsTo } from "@ember-data/model";

export default class Article extends Model {
  @attr('string')
  syncId;

  @belongsTo('library', { async: true, inverse: 'articles' })
  library;

  @belongsTo('issue', { async: true, inverse: 'articles' })
  issue;

  @belongsTo('journal', {async: true, inverse: 'allArticles'})
  journal;

  @attr('string')
  title;

  get parsedTitle() {
    var title = this.title;
    title = title.replace(/\$\$[^$]+\$\$/g, ' '); // Remove anything surrounded by $$
    title = title.replace(/(<\/?(?!\/?[bi]>)[^<>]+>)/g, ' '); // Anything not <i> or <b> remove
    title = title.replace(/(<(\/[bi])>)/g, '$1 '); // Put a space after </i> or </b>
    return title;
  }

  get strippedTitle() {
    var parsedTitle = this.parsedTitle;
    parsedTitle = parsedTitle.replace(/(<(\/[bi])>)/g, '');
    parsedTitle = parsedTitle.replace(/(<([bi])>)/g, '');
    return parsedTitle;
  }

  @attr('string')
  abstract;

  @attr('string')
  authors;

  get authorsPreview() {
    let preview = [];
    const authors = this.authors.split(';');

    if(authors.length < 4) {
      preview = authors.join(';');
    } else {
      preview.push(authors[0]);
      preview.push(authors[1]);
      preview.push(authors[2]);
      preview.push(' … ' + authors[authors.length - 1].trim());
      preview = preview.join(';');
    }

    return preview;
  }

  @attr('boolean')
  inPress;

  @attr('string')
  startPage;

  @attr('string')
  endPage;

  @attr('string')
  doi;

  @attr('string')
  pmid;

  @attr('string')
  pubmedFallbackURL;

  @attr('string')
  contentLocation;

  @attr('string')
  ILLURL;

  @attr('string')
  browzineWebInContextLink;

  @attr('string')
  linkResolverOpenurlLink;

  @attr('string')
  emailArticleRequestLink;

  @attr('string')
  documentDeliveryFulfillmentUrl;

  @attr('string')
  documentDeliveryProvider;

  get browseable() {
    return !isEmpty(this.browzineWebInContextLink);
  }

  @attr('string')
  permalink;

  @attr('string')
  fullTextLocation;

  @attr('string')
  fullTextFile;

  @attr('boolean')
  suppressed;

  @attr('boolean')
  vpnRequired;

  @attr()
  ipRangeError;

  get hasNoEndPage() {
    return !this.endPage;
  }

  get startPageEqualsEndPage() {
    return this.startPage === this.endPage;
  }

  get isSinglePage() {
    return this.hasNoEndPage || this.startPageEqualsEndPage;
  }

  @attr('date')
  date;

  get year() {
    return this.date.getFullYear();
  }

  @attr('boolean')
  openAccess;

  @attr('boolean')
  withinLibraryHoldings;

  @attr('string')
  retractionDoi;

  @attr('date')
  retractionDate;

  @attr('string')
  retractionRelatedUrls;

  @attr('string')
  retractionNoticeUrl;

  @attr('string')
  retractionReasons;

  @attr()
  secondOrderRetractions;

  @attr('string')
  expressionOfConcernDoi;

  @attr('date')
  expressionOfConcernDate;

  @attr('boolean')
  unpaywallDataSuppressed;

  @attr('string')
  illLibraryId;

  @attr('string')
  libraryIllEmail;

  @attr('boolean')
  abandoned;

  @attr('boolean')
  avoidUnpaywallPublisherLinks;
}
