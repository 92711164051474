import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { findDoiInInput } from "../utils/doi-utils";
import { findPmidInInput } from "../utils/pmid-utils";
import prepareArticleLoadingBoxModel from 'libkey-web/utils/prepare-article-loading-box-model';

export default class HoldingsToolFulfillmentRoute extends Route {
  @service applicationSession;
  @service router;
  @service store;
  @service analytics;

  async model(params) {
    const libraryId = params.library_id;
    const applicationSession = this.applicationSession;
    const holdingsToolLibraryGroup = applicationSession.holdingsToolLibraryGroup;
    /*
    TODO: Ideally we'd call `findRecord` here instead of `queryRecord`, with "adapterOptions", something like:
     findRecord('library', libraryId, { adapterOptions: { ill_library_group_id: holdingsToolLibraryGroup.id }, reload: true });
     And then in the library adapter, override `buildQuery` and add "ill_library_group_id" to the query there.
      We can't do that now because of this issue: https://github.com/emberjs/data/issues/5373
      The fix for that hasn't yet made it into any ember-data 2.X release.
    */
    const findLibrary = this.store.queryRecord(
      'library',
      {
        id: libraryId,
        ill_library_group_id: holdingsToolLibraryGroup ? holdingsToolLibraryGroup.id : undefined,
        reload: true,
      });
    const id = params.id_value;
    this.requestedId = id;
    const doiResult = findDoiInInput(id);
    const pmidResult = findPmidInInput(id);
    const findArticleWithJournalAndIssue = this.store.queryRecord(
      'article',
      {
        doi: doiResult.doi,
        pmid: pmidResult.pmid,
        reload: true,
        include: 'issue,journal',
        ill_library_group_id: holdingsToolLibraryGroup ? holdingsToolLibraryGroup.id : undefined,
        force_ill_library: !!holdingsToolLibraryGroup
      });
    return prepareArticleLoadingBoxModel(findArticleWithJournalAndIssue, findLibrary)
      .then(async (model) => {
        const illLibraryId = model.article.illLibraryId;
        if (!illLibraryId) {
          this.router.transitionTo('wayfless-article-or-pmid', libraryId, id, { queryParams: { lendingLibraryLookupFailed: true }});
          return;
        }
        const illLibrary = await this.store.findRecord('library', illLibraryId);
        const illLibraryName = illLibrary.name;
        return {
          ...model,
          holdingsToolLibraryGroup,
          illLibraryName
        };
      });
  }

  async afterModel(model) {
    if (model.article && model.library && model.holdingsToolLibraryGroup) {
      const lendingLibraryId = model.article.illLibraryId;
      const openAccess = model.article.openAccess;
      const articleYear = model.article.year;
      const issueYear = model.issue.year;
      const authors = model.article.authors;
      const lendingLibraryName = model.illLibraryName;
      const requestingLibraryId = model.library.id;
      const requestingLibraryName = model.library.name;
      const libraryGroupId = model.holdingsToolLibraryGroup.id;
      const journalName = model.journal.title;
      const issn = model.journal.issn;
      const eissn = model.journal.eissn;
      const event = {
        category: 'ArticleLookupAvailableAtLendingLibrary',
        articleId: model.article.id,
        doi: model.article.doi,
        pmid: model.article.pmid,
        lendingLibraryId,
        lendingLibraryName,
        requestingLibraryId,
        requestingLibraryName,
        libraryGroupId,
        journalName,
        articleYear,
        issueYear,
        openAccess,
        authors,
        issn,
        eissn
      };
      try {
        await this.analytics.recordEvent(event);
      } catch (err) {
        console.log(`Error in recordEvent(${JSON.stringify(event)}): ${err.message||err.stack}`);
      }
    }
  }

  setupController(controller, model) {
    controller.set('rightPanelCoverClass', this.rightPanelCoverClass);
    controller.set('article', model.article);
    controller.set('requesterEmail', model.article.libraryIllEmail);
    controller.set('issue', model.issue);
    controller.set('journal', model.journal);
    controller.set('holdingsToolLibraryGroup', model.holdingsToolLibraryGroup);
    controller.set('illLibraryName', model.illLibraryName);
    controller.set('library', model.library);
    controller.set('requestedId', this.requestedId);
  }

  @action
  changeLibrary() {
    const id = this.requestedId;
    this.applicationSession.set('selectedLibrary', undefined);
    this.router.transitionTo('choose-library', id);
  }
}
