import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import window from 'ember-window-mock';


export default class WayflessLandingPageRoute extends Route {
  @service analytics;
  @service applicationSession;
  @service unpaywall;
  @service router;
  @service store;
  @service flashMessages;
  @service auth;

  beforeModel(transition) {
    let { to: { params } } = transition;

    const analytics = this.analytics;
    const applicationSession = this.applicationSession;

    analytics.setProductDimension('LibkeyIO');

    const libraryId = params.library_id;
    if (libraryId === 'unaffiliated') {
      applicationSession.set('selectedLibrary', 'unaffiliated');
      return this.router.transitionTo('index');
    }
  }

  async model(params, transition) {
    let { to: toRouteInfo } = transition;

    const libraryId = toRouteInfo.params.library_id;

    return this.store.findRecord('library', libraryId)
      .catch((err) => {
        const applicationSession = this.applicationSession;
        console.log(`Could not load library ${libraryId}, restoring original affiliation`);
        applicationSession.set('selectedLibrary', applicationSession.originalSelectedLibrary);
        throw err;
      });
  }

  afterModel(model) {
    const applicationSession = this.applicationSession;

    if (!model) {
      applicationSession.set('selectedLibrary', undefined);
      return this.router.transitionTo('index');
    }

    if (!model?.subscriptions?.libkey?.active) {
      this.flashMessages.alert('This library is not subscribed to LibKey.');
      window.location.replace('https://thirdiron.com/libkey-link-subscription-expired/')
      return;
    }

    applicationSession.set('selectedLibrary', model.id);
    return this.router.transitionTo('index');
  }
}
